
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import ReactPlayer from 'react-player'
import useFetchData from "../../components/UseFetchData";
import { ResponsiveSwarmPlot, SwarmPlotAnnotations } from "@nivo/swarmplot";
const _ = require("lodash");
import * as d3 from "d3";
import SP500SwarmPlot from "../../components/SP500SwarmPlot";
import Select from "react-select";
import { SingleSelect } from "react-select-material-ui";
import Grid from '@mui/material/Grid';
export const ggbParameters = {
  // material_id: "",
  width: "100%",
  height: "100%",
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const opts = {
  height: '390',
  width: '510',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Intro = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4' mdxType="ReactPlayer" />
  </div>;
export const DefaultBox = () => <div style={{
  padding: 20,
  backgroundColor: 'yellow'
}} />;
export const Text4 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const componetArray = [<DefaultBox mdxType="DefaultBox" />, <Box1 mdxType="Box1" />];
export const indexArray = {
  button1: 1,
  text14: 1
};
export const prepareNivo = (data, valueVariable) => {
  const min = d3.min(data, d => d[valueVariable]);
  const max = d3.max(data, d => d[valueVariable]);
  const colors_returns = d3.scaleDiverging([min, 0, max], d3.interpolatePiYG);

  const valArray = _.map(data, d => d[valueVariable]);

  const valArray_sorted = valArray.sort(d3.ascending);
  const q1 = d3.quantile(valArray_sorted, 0.25);
  const q2 = d3.quantile(valArray_sorted, 0.5);
  const q3 = d3.quantile(valArray_sorted, 0.75);
  const IQR = q3 - q1;
  const threshold = 1.5 * IQR;

  const isOutlier = d => d < q1 - threshold || d > q3 + threshold;

  const stocks_data_colors = _.map(data, obj => {
    const fill_color = colors_returns(obj[valueVariable]);
    const stroke_color_check = isOutlier(obj[valueVariable]) ? "black" : "lightgray";
    const font_color = isOutlier(obj[valueVariable]) ? "#FFFDE7" : "black";
    return { ...obj,
      stroke_color: stroke_color_check,
      fill_color: fill_color,
      font_color: font_color,
      id: obj["Ticker"],
      group: obj["Sector"]
    };
  });

  const options = _.map(data, d => {
    return {
      value: d.Ticker,
      label: d.Company + " : " + d.Ticker
    };
  });

  const options_sorted = _.sortBy(options, "value");

  return <div className="App"> 
      {Array.isArray(stocks_data_colors) && stocks_data_colors.length != 0 && <SP500SwarmPlot data={stocks_data_colors} valueVariable={valueVariable} groupVariable={"Sector"} options_sorted={options_sorted} mdxType="SP500SwarmPlot" />}
   
  </div>;
};
export const MultiFecthApp = () => {
  const [data, setData] = React.useState([]);
  const [valueVariable, SetValueVariable] = React.useState("PerfYear");
  const prepareNivoComponent = React.useMemo(() => prepareNivo(data, valueVariable), [data, valueVariable]);

  const fetchExternalData = () => {
    return Promise.all([fetch("https://storage.googleapis.com/ggb_resources/finviz_screen_json/finviz_returns_colab_sp500_2022_3_25.json")]).then(results => Promise.all(results.map(result => result.json())));
  };

  React.useEffect(() => {
    fetchExternalData().then(response => {
      const combined_array = response[0];
      setData(combined_array);
    }).catch(console.error);
  }, []);

  function handleChangeSingle(d) {
    SetValueVariable(d);
  }

  return <div className="App">
     {<>
           <SingleSelect style={{
        width: 140,
        zIndex: 60000,
        right: 0,
        color: "gray",
        margin: 'auto',
        position: 'fixed',
        top: 0,
        backgroundColor: "#90caf9"
      }} label="Choose Period" defaultValue="PerfYear" // closeMenuOnSelect={true}
      // components={{ IndicatorsContainer }}
      // defaultValue={[options[0], options[1]]}
      onChange={handleChangeSingle} options={["PerfMonth", "PerfQuart", "PerfHalf", "PerfYear", "PerfWeek"]} // isMulti={true}
      // styles={colourStyles}
      mdxType="SingleSelect" /> 
      {prepareNivoComponent}
      </>}
    </div>;
};

const layoutProps = {
  ggbParameters,
opts,
Box1,
Intro,
DefaultBox,
Text4,
componetArray,
indexArray,
prepareNivo,
MultiFecthApp
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">











    <MultiFecthApp mdxType="MultiFecthApp" />
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;