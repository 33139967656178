
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
export const meta = {
  title: " This is my Title2",
  chapter: "Chapter1",
  ispublished: true,
  date: "21 dec 2021",
  author: "Pavan Kumaar Mirla"
};
export const ggbParameters = {
  // material_id: "",
  // width: 1800,
  // height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const opts = {
  height: '390',
  width: '510',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Box2 = () => <div style={{
  padding: 20,
  backgroundColor: 'green'
}} />;
export const DefaultBox = () => <div style={{
  padding: 20,
  backgroundColor: 'yellow'
}} />;
export const Text4 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const componetArray = [<DefaultBox mdxType="DefaultBox" />, <Box1 mdxType="Box1" />, <Box2 mdxType="Box2" />];
export const indexArray = {
  button1: 1,
  text14: 1
};
export const Box11 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="FAwDrUqpGUI" opts={opts} mdxType="YouTube" />
  </div>;
export const Box22 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;

const layoutProps = {
  meta,
ggbParameters,
opts,
Box1,
Box2,
DefaultBox,
Text4,
componetArray,
indexArray,
Box11,
Box22
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">










  <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/math/quadratic_function.json" ggbParameters={ggbParameters} appId="id1" email="pavanmirla@gmail.com" data={[{
      component: <Box1 mdxType="Box1" />,
      button: "button1"
    }, {
      component: <Box2 mdxType="Box2" />,
      button: "text14"
    }, {
      component: <Text4 mdxType="Text4" />,
      button: "text4"
    }]} mdxType="GeogebraDraw" />


    <div>
  <style dangerouslySetInnerHTML={{
        __html: "\n  .gumroad-follow-form-embed {\n    zoom: 1;\n    display: flex; }\n    .gumroad-follow-form-embed * {\n      margin: 0;\n      border: 0;\n      padding: 0;\n      outline: 0;\n      box-sizing: border-box !important; }\n    .gumroad-follow-form-embed input {\n      flex: 1;\n      margin-right: .75rem;\n      outline: none;\n      font-family: \"Mabry Pro\", sans-serif;\n      padding: 0.875rem 1rem;\n      font-size: 1rem;\n      line-height: 1.3;\n      border: solid 0.0625rem #000;\n      border-radius: 0.25rem;\n      display: block;\n      width: 100%;\n      background-color: #ffffff;\n      color: #000; }\n    .gumroad-follow-form-embed button {\n      background: #000;\n      color: #fff;\n      font-size: 1rem;\n      line-height: 1.3;\n      padding: 0.875rem 1rem;\n      border: solid 0.0625rem #000;\n      border-radius: 0.25rem;\n      font-family: \"Mabry Pro\", sans-serif;\n      cursor: pointer;\n      width: -webkit-fit-content;\n      width: -moz-fit-content;\n      width: fit-content;\n      transition: all 0.14s ease-out; }\n      .gumroad-follow-form-embed button:hover {\n        background-color: #ff90e8;\n        color: #000;\n        transform: translate(-0.25rem, -0.25rem);\n        box-shadow: 4px 4px 0px #000; }\n"
      }} />
  <form action="https://app.gumroad.com/follow_from_embed_form" className="form gumroad-follow-form-embed" method="post">
    <input name="seller_id" type="hidden" defaultValue={2854434058737} />
    <input name="email" placeholder="Your email address" type="email" />
    <button data-custom-highlight-color type="submit">Follow</button>
  </form>
    </div>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;