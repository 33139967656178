import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Lock from '@mui/icons-material/Lock';
import Link from '@mui/material/Link';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function BasicModal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const whichComp = props.comp
  let renderThis = <></>
  if (whichComp=="lock") {
    renderThis =  <Button onClick={handleOpen}><Lock style={{color:"gold"}}/> </Button>;
  } else {
    renderThis =  <Button onClick={handleOpen}> See Sample Chapter/ Sign up  </Button>;
  }
  const mailto= "mailto:office@21ifm.com?subject=Send me sample chapter!&body=Thanks!"
  const label = "sample chapter"
  return (
    <div>
     {renderThis}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          This interactive book on options is scheduled for release on April 21st, 2022.
          Meanwhile, please have a look at sample chapter
          - office@21ifm.com
        <Link
            to='#'
            onClick={(e) => {
                window.location = mailto;
                e.preventDefault();
            }}
            style={{ textDecoration: 'none' }}
        >
     
            <Box marginTop={2} display={'flex'} justifyContent={'center'}>
            <Button
              color={'primary'}
              variant={'contained'}
              size={'large'}
            >
              {label}
            </Button>
          </Box>
        </Link>
          {/* <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfaJW1pe1fqVYFGLoGCu6mDbTt8ldu1ZfKRidMOejf6eu0f3w/viewform?embedded=true" width="100%" height="300" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> */}

        </Box>
      </Modal>
    </div>
  );
}
