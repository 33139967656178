
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import VerticalSteps from "../../components/VerticalSteps";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import ReactPlayer from 'react-player'
import Stack from '@mui/material/Stack';
import Button from "@material-ui/core/Button";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Form from "../../components/NetlifyForm";
import Content from "../../components/Content";
import Container from "../../components/Layouts/Container";
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
import ButtonMailto from "../../components/ButtonMailto.js";
export const items1 = [{
  title: 'What is Margin and why is Margin required for stocks?',
  subtitle: "Margin is borrowing money from broker. Trader can genrally borrow(liability portion) up to 50/% of Asset value and he invests(equity portion) the rest. Interest is paid on borrowed funds"
}, {
  title: 'What is Initial Margin requirement?',
  subtitle: 'An Initial Margin Requirement refers to the percentage of equity required when an investor opens a position. 50% of total Asset value can be margined'
}, {
  title: 'What is Maintenance Margin?',
  subtitle: " The minimum equity that an investor must maintain in a margin account after a purchase has been made is maintenance margin. Brokers typically set maintenance margin at 25 percent. 30% is standard.  In the event that equity falls below this treshold, the Broker will ask you to add more funds to raise equity above the maintenance requirement"
}, {
  title: 'What is Margin call? ',
  subtitle: " This is a demand from broker to add money to increase equity above maintenace requirement or close out your position"
}, {
  title: 'What are advantages of Margin Trading?',
  subtitle: 'A trader can leverage the value of securities she already owns to increase the size of her investment through margin. Stocks can be shorted in Margin account.Margin account is convenient line of credit '
}];
export const items2 = [{
  title: 'Position liquidation',
  subtitle: "The broker might liquidate your other assets if you cannot deposit additional funds to meet the maintenance margin requirement"
}, {
  title: 'What is portfolio margin acount?',
  subtitle: 'This is a margin account with leverage(>50%), that is greater than the normal(reg-t) margin account that allows 50% leverage'
}, {
  title: 'Maintenace requirement might depend on stock volatility',
  subtitle: 'Brokers may set a higher maintenance requirement if the credit risk is higher or the stock is excessively volatile'
}];
export const ggbParameters = {
  // material_id: "",
  // width: "100%",
  // height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Link = makeShortcode("Link");
const layoutProps = {
  items1,
items2,
ggbParameters
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Margin requirements`}</h1>

    <Container mdxType="Container">
  <Content items={items1} title={"Margin for Stocks"} mdxType="Content" />
    </Container>
    <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/debt_equity_margin_3.json" ggbParameters={ggbParameters} appId="appId1margincall" email="pavanmirla@gmail.com" data={[]} mdxType="GeogebraDraw" /> 

    <Container mdxType="Container">
  <Content items={items2} title={"Key takeways"} mdxType="Content" />
    </Container>

  <Container mdxType="Container">
   <Typography variant="subtitle1" gutterBottom component="div" mdxType="Typography">
        References
      </Typography>
    <Typography variant="caption" display="block" gutterBottom mdxType="Typography">
        <br />
    <Link href="https://cdn.cboe.com/resources/options/margin_manual_april2000.pdf" mdxType="Link">
     {'CBOW Margin Manual'}
    </Link>
      <Link href="https://www.reddit.com/r/Questrade/comments/gexhl7/how_does_questrade_calculate_the_maintain_excess/" mdxType="Link">
     {'Questrade Margin Excess'}
    </Link>
    <br />
      
      </Typography>
    </Container>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;