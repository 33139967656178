
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import LoadGGBLayoutHtml from "../../components/LoadGGBLayoutHtml";
import SingleCard from "../../components/singleCard";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import ButtonBase from "@material-ui/core/ButtonBase";
import SimpleVideoCard from '../../components/SimpleVideoCard';
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import ReactPlayer from 'react-player'
import Button from "@material-ui/core/Button";
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Demo from '../../components/DraggableDialog';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import  {useState,useEffect,useReducer,useRef} from "react";
import Content from "../../components/Content";
import Container from "../../components/Layouts/Container";
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
export const data = [{
  title: "1.Call price simulation",
  id: 1,
  script: 'Execute({"RunClickScript(scenarioB)"})',
  text: "How does Call value change with change in underlying price?",
  image: "https://storage.googleapis.com/chapters-ifm/testfolder/2022/flowchart1.png",
  video: ""
}, {
  title: "2.Call price derivation",
  id: 2,
  script: 'Execute({"RunClickScript(formulasB)"})',
  text: "Derive Call price from Put Call parity ",
  image: "https://storage.googleapis.com/chapters-ifm/testfolder/2022/flowchart1.png",
  video: ""
}, {
  title: "3.Intrinsic value",
  id: 3,
  script: 'Execute({"RunClickScript(intextB)"})',
  text: "What factors influence Options Intrinsuic value?",
  image: "https://storage.googleapis.com/chapters-ifm/testfolder/2022/flowchart1.png",
  video: ""
}, {
  title: "4.Moneyness of Option",
  id: 4,
  script: 'Execute({"RunClickScript(itmB)"})',
  text: "When is Call option ITM, ATM and OTM",
  image: "",
  video: "https://www.youtube.com/embed/88eG8-3qpds"
}, {
  title: "5.Strike price, Asignment and exercise",
  id: 5,
  script: 'Execute({"RunClickScript(excB)"})',
  text: "Rights and Obligations of Call options seller and buyer",
  image: "https://storage.googleapis.com/chapters-ifm/testfolder/2022/flowchart1.png",
  video: ""
}];
export const Text9 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/homevideo.mp4' mdxType="ReactPlayer" />
  </div>;
export const Text14 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4' mdxType="ReactPlayer" />
  </div>;
export const items1 = [{
  title: 'Why does the payoff of a call before expiration look like a curve?',
  subtitle: "Black Scholes' formula determines the option premium based on several factors, including volatility, time, interest rates, and stock price. It captures the nonlinear relationship between premium and stock price. As a result, the payoff looks like a curve "
}, {
  title: 'What is lower bound of long call? Why is it called Intrinsic Value?',
  subtitle: 'The blue line is the payoff line for long call. It refers to the difference between the strike price and the underlying stock price. The lower bound, also called the intrinsic value, is shifted down based on how much was paid for owning the call. The intrinsic value is zero if the calculated value (stock - strike) is negative.'
}, {
  title: 'Why is option premium higher than Intrinsic value before expiry?',
  subtitle: 'Stock price is the sole factor determining intrinsic value. Options, however, are determined by other factors.  Extrinsic value considers other factors that affect an options price, including when it will expire and the volatility of the stock.'
}, {
  title: 'How do volatility, time to expiry, stock price, rate of interest influence long call option premium?',
  subtitle: "Try modifying the parameters on the left of this app to see what impact they have on the option's payoff."
}];
export const items2 = [{
  title: 'Non linear option payoff',
  subtitle: " Before maturity, the premium is not linearly influenced by volatility, time, the stock value, and interest rates. This is a result of Black Scholes pricing."
}, {
  title: 'Effect of volatility on long call option premium',
  subtitle: 'Volatility and option premium are positively correlated. In other words, as volatility increases, option premiums increase. When volatility decreases, option premiums decrease. '
}, {
  title: 'Effect of time to expiry on long call option premium',
  subtitle: 'Time left to expire and option premiums are negatively correlated. Thus, as time moves towards maturity, option premiums decrease. As the expiration date approaches, option premiums decrease. '
}, {
  title: 'Auto exercise',
  subtitle: 'According to Options Clearing Corporation (OCC) rules, any contract that is ITM at expiration is subject to auto-exercise. '
}];
export const ggbParameters = {
  // material_id: "",
  // width: "100%",
  // height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const BoxExample = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato',
  minWidth: 300
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const items3 = [{
  title: 'Synthetic stock with deep ITM calls',
  subtitle: "Delta of deep ITM calls is close 1o 1. Advantage of the higher delta is that the options move more in line with the stock price. Since break even is close to the stock price, deep ITM call serves as a stock replacement.  "
}, {
  title: 'Extrinsic value is lower for deep ITM Calls',
  subtitle: 'Verify this will the explainer above. Buying so far in the money you pay much less for extrinsic value. This means things dont have as much to lose to volatility swings or decay as long as the stock price stays up. '
}, {
  title: 'Liquidity problem with deep ITM calls',
  subtitle: ' This can make it hard to get a good price or find a trade at all. '
}];

const layoutProps = {
  data,
Text9,
Text14,
items1,
items2,
ggbParameters,
BoxExample,
items3
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <h1>{`EXTRINSIC VALUE oF A LONG CALL`}</h1>

    <Container mdxType="Container">
  <Content items={items1} title={"Basics"} mdxType="Content" />
    </Container>
    <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/call_2022.json" ggbParameters={ggbParameters} appId="appId2" email="pavanmirla@gmail.com" data={[{
      component: <Text9 mdxType="Text9" />,
      button: "text14"
    }, {
      component: <Text9 mdxType="Text9" />,
      button: "text9"
    }]} mdxType="GeogebraDraw" /> 

    <Container mdxType="Container">
  <Content items={items2} title={"Here are the main points to remember"} mdxType="Content" />
    </Container>
    <h1>{`Verify your understanding`}</h1>
    <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/quiz_option_premia_long_call_1.json" ggbParameters={ggbParameters} appId="appId1" email="pavanmirla@gmail.com" data={[{
      component: <Text9 mdxType="Text9" />,
      button: "text14"
    }, {
      component: <Text9 mdxType="Text9" />,
      button: "text9"
    }]} mdxType="GeogebraDraw" /> 


    {
      /* <>
      <LoadGGBLayoutHtml 
      data={data} 
      geogebraJSON = {"https://storage.googleapis.com/chapters-ifm/testfolder/calls/calls_lesson.json"}
      buttonTitle="Click here to load Call Options Intro App"/>
      </> */
    }

    <Container mdxType="Container">
  <Content items={items3} title={"Buying deep in the money calls"} mdxType="Content" />
    </Container>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;