
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import ReactPlayer from 'react-player'
import Button from '@mui/material/Button';
import Content from "../../components/Content";
import Straddle from "../../components/Pdf/Straddle"
import Container from "../../components/Layouts/Container";
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
import Link from '@mui/material/Link';
export const meta = {
  title: " This is my Title2",
  chapter: "Chapter1",
  ispublished: true,
  date: "21 dec 2021",
  author: "Pavan Kumaar Mirla"
};
export const ggbParameters = {
  // material_id: "",
  // width: 1700,
  // height: 950,
  // borderColor: "#1E1E1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const opts = {
  height: '390',
  width: '510',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Intro = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4' mdxType="ReactPlayer" />
  </div>;
export const DefaultBox = () => <div style={{
  padding: 20,
  backgroundColor: 'yellow'
}} />;
export const Text4 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const componetArray = [<DefaultBox mdxType="DefaultBox" />, <Box1 mdxType="Box1" />];
export const indexArray = {
  button1: 1,
  text14: 1
};
export const items1 = [{
  title: 'What is a Straddle?',
  subtitle: 'A straddle purchase gives the buyer the potential to make large profits if the stock moves far enough either way. Losses for the buyer are limited by the amount of the initial investment.'
}, {
  title: 'Which option strikes and options types are traded?',
  subtitle: 'The straddle purchase consists of buying both a put and a call based on the same terms - the same underlying stock, the same strike price, and the same expiration date.'
}, {
  title: 'What makes it a Debit strategy?',
  subtitle: 'Trader is buying two options, hence net cost (a net debit) trade '
}, {
  title: 'What happens if price remains between breakeven prices and time decays?',
  subtitle: ''
}, {
  title: 'What is the Breakeven?',
  subtitle: 'Breakeven is the point at which profit and loss are equal. Two breakeven points exist. The left breakeven point is strike  minus total premium. The right breakeven point is strike K plus total premium.'
}];
export const items2 = [{
  title: 'What is a Iron Condor Startegy',
  subtitle: 'Iron Condor is a strategy that collects premium by using four options in a high volatility environment.The trade will be profitable if the stock remains within a defined range or volatility drops'
}];

const layoutProps = {
  meta,
ggbParameters,
opts,
Box1,
Intro,
DefaultBox,
Text4,
componetArray,
indexArray,
items1,
items2
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">











    <h1>{`Long Straddle`}</h1>
    <p>{`In situations where someone thinks stock prices are likely to change significantly, but they aren't sure of the direction, he'll consider buying a straddle.`}</p>
    <br />
    <p>{`Buying a long straddle might be smart if a firm is about to face a yes/no decision - such as a merger, or waiting for the FDA to approve a pharma company. `}</p>
    <br />
    <p>{`A straddle will have a positive payoff if there is a large rise or fall in the stock price.`}</p>

    <Container mdxType="Container">
  <Content items={items1} title={"Basics"} mdxType="Content" />
    </Container>
    {
      /* jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/straddle_2022_template.json" */
    }

    <h1>{`Straddle summary poster`}</h1>
    <Straddle mdxType="Straddle" />
    <h1>{`Straddle interactive`}</h1>
  <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/long_straddle_greeks_scripted_3.json" ggbParameters={ggbParameters} appId="id2straddle1" email="pavanmirla@gmail.com" data={[{
      component: <Intro mdxType="Intro" />,
      button: "text11"
    }, {
      component: <Text4 mdxType="Text4" />,
      button: "text3"
    }]} mdxType="GeogebraDraw" />
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;