
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import VerticalSteps from "../../components/VerticalSteps";
import IfPaidUser from "../../components/IfPaidUser";
import VideoSection from "../../components/VideoSection";
import "../../markdown-styles/styles.css";
import ReactPlayer from 'react-player'
import Stack from '@mui/material/Stack';
import Button from "@material-ui/core/Button";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
export const ggbParameters = {
  // material_id: "",
  // width: "100%",
  // height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};

const layoutProps = {
  ggbParameters
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Delta for Long Call`}</h1>
    <p>{`This is a sample chapter. `}</p>
    <VideoSection video={"https://storage.googleapis.com/chapters-ifm/testfolder/2022/DELTA/call_delta.mp4"} mdxType="VideoSection" />
    <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/DELTA/long_call_delta_apr_2022_2.json" ggbParameters={ggbParameters} appId="appIdGreeks" email="pavanmirla@gmail.com" data={[]} mdxType="GeogebraDraw" /> 
    <h1>{`Long Call: All Greeks`}</h1>
    <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/calls_all_greeks/calls_greeks_2022_apr_5.json" ggbParameters={ggbParameters} appId="appIdAllGreeks" email="pavanmirla@gmail.com" data={[]} mdxType="GeogebraDraw" /> 

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;