import React from 'react';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

const mock = [
  {
    description: '1.Clara Bertoletti',
    title: '1.MUI lover',
    avatar: 'https://storage.googleapis.com/chapters-ifm/testfolder/images/hoodie.png',
  },
  {
    description: '2.Jhon Anderson',
    title: 'Senior Frontend Developer',
    avatar: 'https://storage.googleapis.com/chapters-ifm/testfolder/images/hoodie.png',
  },
  {
    description: 'Chary Smith',
    title: 'SEO at Comoti',
    avatar: 'https://storage.googleapis.com/chapters-ifm/testfolder/images/hoodie.png',
  },
  {
    description: 'Clara Bertoletti',
    title: 'MUI lover',
    avatar: 'https://storage.googleapis.com/chapters-ifm/testfolder/images/hoodie.png',
  },
  {
    description: 'Jhon Anderson',
    title: 'Senior Frontend Developer',
    avatar: 'https://storage.googleapis.com/chapters-ifm/testfolder/images/hoodie.png',
  },


  
];

const GridList = ({topics}) => {
  const theme = useTheme();
  debugger
  return (
    <Box sx={{ mx: "auto",}}   
         padding={{ xs: 1, sm: 12 }}
         // marginLeft={2}
    >
      <Box marginBottom={2}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 900,
          }}
          gutterBottom
          color={'secondary'}
          align={'center'}
          variant="h2"
        >
        TOPICS
        </Typography>
      </Box>
      <Grid container spacing={1}>
        {topics.map((item, i) => (
          <Grid item xs={12} md={6} key={i}>
            <Box
            sx ={
              {
                maxHeight: 120,
                // ml: 4,
                // mr:4,
                borderColor: '#1e1e1e',
                borderRadius: 2 ,
                // gap: 2,
                color: 'primary.main',
                boxShadow: 1 ,
                 margin: 2 ,
                 '&:hover': {
                    // background: "#1e1e1e",
                    opacity: 0.5
                 },
                //  bgcolor: 'secondary.main',
                //  color: 'secondary.contrastText',
              }
            }
              width={1}
              height={.8}
              component={"div"}
              boxShadow={0}
              variant={'outlined'}
              bgcolor={'#373737'}
            >
              <CardContent sx={{ padding: 1 }}>
              <Box
                    sx={{
                        color: 'primary.dark',
                        display: 'inline',
                        fontWeight: 'bold',
                        mx: 0.5,
                        fontSize: 24,
                        
                    }}
                    >
                    {i+1}
                </Box>
                <Typography variant="subtitle2"  style={{color:"#90caf9"}} display="inline">{item.title} / </Typography>
                <Typography variant="body1" style={{color:"gray"}} display="inline">{item.description}</Typography>

              </CardContent>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default GridList;