
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import VerticalSteps from "../../components/VerticalSteps";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import ReactPlayer from 'react-player'
import Stack from '@mui/material/Stack';
import Button from "@material-ui/core/Button";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Form from "../../components/NetlifyForm";
import Content from "../../components/Content";
import Container from "../../components/Layouts/Container";
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
export const items1 = [{
  title: 'What is the goal?',
  subtitle: "Profit potential is substantial and risk is limited "
}, {
  title: 'How is it setup?',
  subtitle: 'Created with Puts. Selling higher-strike put and buying two lower strike puts'
}, {
  title: 'Is this a net debit or net credit trade?',
  subtitle: 'It depends on the chosen time to maturity, volatility levels and distance between strikes'
}];
export const items2 = [{
  title: 'Early Assignment',
  subtitle: 'In case the option is exercised, holders of short stock options have to fulfill their obligations. The risk of short puts is high. If the short puts are exercised, you get 100 shares of stock. If not enough money is in the margin account, an assignment can trigger a margin call'
}];
export const ggbParameters = {
  // material_id: "",
  // width: "100%",
  // height: 950,
  borderColor: "#1E1E1E",
  enableShiftDragZoom: false,
  showToolBar: false
};

const layoutProps = {
  items1,
items2,
ggbParameters
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`ratio spread with puts`}</h1>

    <Container mdxType="Container">
  <Content items={items1} title={"Setup Ratio spread"} mdxType="Content" />
    </Container>
    <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/strategies/bearish_pot_ratio_backspread_lkg.json" ggbParameters={ggbParameters} appId="appId1OutRatioBS" email="pavanmirla@gmail.com" data={[]} mdxType="GeogebraDraw" /> 

    <Container mdxType="Container">
  <Content items={items2} title={"Risks"} mdxType="Content" />
    </Container>

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;