
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import { BuzzFeedQuiz } from "react-buzzfeed-quiz";
import "../../markdown-styles/styles.css";
import "react-buzzfeed-quiz/lib/styles.css";
export const NumberContext = React.createContext();
export const Box1 = () => <div style={{
  padding: 1,
  backgroundColor: 'tomato',
  width: "100%"
}}>
    <YouTube videoId="EfmTWu2yn5Q" opts={opts} mdxType="YouTube" />
  </div>;
export const opts = {
  height: '380',
  width: '99%',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1
  }
};
export const Quiz = () => {
  return <>
    <BuzzFeedQuiz title={'May 20th 2022'} description={'Test your knowledge'} autoScroll={true} questions={[{
      question: <p style={{
        color: "white"
      }}></p>,
      questionRelativeToImage: "adjacent",
      answerArrangement: "row",
      backgroundImageSrc: "https://storage.googleapis.com/chapters-ifm/testfolder/2022/delta_3d_2022.gif",
      answers: [{
        answer: 'answer1 question1',
        resultID: 0,
        revealResponse: {
          title: <>
                            <h2> Tag </h2>
                            <Box1 mdxType="Box1" />
                            </>,
          description: "text",
          image: "https://storage.googleapis.com/chapters-ifm/testfolder/2022/delta_3d_2022.gif",
          imageAttribution: "Via Pexels"
        }
      }, {
        answer: 'answer2 question1',
        resultID: 1
      }, {
        answer: 'answer3 question1',
        resultID: 2
      }]
    }, {
      question: "question2",
      answers: [{
        answer: 'answer1 question2',
        resultID: 0
      }, {
        answer: 'answer2 question2',
        resultID: 1
      }, {
        answer: 'answer3 question2',
        resultID: 2
      }]
    }, {
      question: "question3",
      answers: [{
        answer: 'answer1 question3',
        resultID: 0
      }, {
        answer: 'answer2 question3',
        resultID: 1
      }, {
        answer: 'answer3 question3',
        resultID: 2
      }]
    }]} results={[{
      title: "Your first result title goes here",
      description: "Your first result description goes here",
      resultID: 0
    }, {
      title: "Your first result title goes here",
      description: "Your first result description goes here",
      resultID: 1
    }, {
      title: "Your first result title goes here",
      description: "Your first result description goes here",
      resultID: 2
    }]} mdxType="BuzzFeedQuiz" />
  </>;
};

const layoutProps = {
  NumberContext,
Box1,
opts,
Quiz
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <Quiz mdxType="Quiz" />
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;