import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { palette } from "@material-ui/system";
import ReactPlayer from 'react-player'
import Checkbox from '@mui/material/Checkbox';
import MessageModal from "./MessageModal"
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
const useStyles = makeStyles({
  root: {
    maxWidth:255,
    minWidth: 100,
    maxHeight: 320,
    minHeight: 250,
   
  },
  image: {

  },
  video: {

  },
  content: {
    // maxHeight: 200,
    // backgroundColor: "#ffcf02"
    // backgroundColor: "#e8f5fc"
    // maxWidth: 400,
    // minWidth: 300
  },
  cardButton: {
    // backgroundColor: "gray",
    // color: "black",
    fontWeight: "bold"

  },
  lightFont: {
    fontWeight: 200
  }
});



export default function SingleCard({ title, image,video, description , level, token}) {
  const [checked, setChecked] = React.useState(true);
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const classes = useStyles();
  const VidCom = <ReactPlayer controls="false" width="100%" height="100%" 
          url={video}
          
          config={{
            file: {
              attributes: {
                onContextMenu: e => e.preventDefault(),
                controlsList: 'nodownload nofullscreen noremoteplayback',
              },
            },
          }} />
  const CardMediaComp  = 
        <>   
          <CardMedia 
            component={image == "" ? 'video':'image'}
            style={{height: "20%", width: "100%", paddingTop: '56.25%'}}
            image={image}
          />
        </>
  const CompLoad = image == "" ? VidCom:CardMediaComp
  return (
    <div style={{ display:'flex', justifyContent:'center' }}>
    <Card className={classes.root}>
      <CardActionArea>
        {/* <CardMedia className={classes.media} image={image} title={title} /> */}
        <div >
          {CompLoad}
         </div>
        <CardContent className={classes.content}>
          {/* <Typography
            className={classes.cardButton}
            gutterBottom
            // variant="h5"
            // component="h2"
          >
            {title}
          </Typography> */}
          <Typography style={{color:"#90caf9"}} display="inline">{title} / </Typography>
          <Typography style={{color:"gray"}} display="inline">{description}</Typography>
          <Chip label={level} size="small" variant="outlined" color="primary" />

          {/* <Typography variant="body2" color="textSecondary" component="p">
            {description}
          </Typography> */}
         { token===null && <MessageModal comp="lock"/>}
        </CardContent>
      </CardActionArea>

    </Card>
    </div>
  );
}
