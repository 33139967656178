import React from "react";

const GRCard = () => {
    return (
      <>
        <div className="section small wf-section">
          <div className="container">
            <div className="w-embed">
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    "\n  @media only screen and (max-width: 991px) and (min-width: 767px) {\n\t  .grid-thirds .card-wrap:last-child {\n  \t\tdisplay: none;\n\t  }\n"
                }}
              />
            </div>
            <div className="grid-thirds">
              <a
                href="http://localhost:3000/delta_neutral_port"
                target="_blank"
                className="card-wrap w-inline-block"
              >
                <div className="card-thumbnail-wrap">
                  <img
                    src="https://assets-global.website-files.com/6171b265e5c8aa59b42c3472/619d0efe12c8a5dac1d0ef52_making-money-with-jose-rosado.png"
                    loading="lazy"
                    alt
                    sizes="(max-width: 479px) 89vw, (max-width: 767px) 91vw, (max-width: 991px) 42vw, (max-width: 1919px) 29vw, 30vw"
                    srcSet="https://assets-global.website-files.com/6171b265e5c8aa59b42c3472/619d0efe12c8a5dac1d0ef52_making-money-with-jose-rosado-p-500.png 500w, https://assets-global.website-files.com/6171b265e5c8aa59b42c3472/619d0efe12c8a5dac1d0ef52_making-money-with-jose-rosado-p-800.png 800w, https://assets-global.website-files.com/6171b265e5c8aa59b42c3472/619d0efe12c8a5dac1d0ef52_making-money-with-jose-rosado.png 1030w"
                    className="card-thumbnail"
                  />
                </div>
                <div className="card-content">
                  <h3 className="card-title">
                    Link to Component
                  </h3>
                  <div className="card-description">
                    Jose and Justin talk about affiliate marketing, personal branding,
                    communicating with your audience, and more.{" "}
                  </div>
                  <div className="card-read-more">
                    <strong>Read More -&gt;</strong>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        </>
    );
  }


export default GRCard;
