
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import Content from "../../components/Content";
import Container from "../../components/Layouts/Container";
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
export const meta = {
  title: " This is my Title2",
  chapter: "Chapter1",
  ispublished: true,
  date: "21 dec 2021",
  author: "Pavan Kumaar Mirla"
};
export const ggbParameters = {
  // material_id: "",
  // width: 1800,
  // height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const opts = {
  height: '390',
  width: '510',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="EfmTWu2yn5Q" opts={opts} mdxType="YouTube" />
  </div>;
export const Box2 = () => <div style={{
  padding: 20,
  backgroundColor: 'green'
}} />;
export const DefaultBox = () => <div style={{
  padding: 20,
  backgroundColor: 'yellow'
}} />;
export const Text4 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const componetArray = [<DefaultBox mdxType="DefaultBox" />, <Box1 mdxType="Box1" />, <Box2 mdxType="Box2" />];
export const indexArray = {
  button1: 1,
  text14: 1
};
export const items1 = [{
  title: 'What is meant by exercising a Call option?',
  subtitle: "The holder of a call option can buy 100 shares of a stock at a specific price within a specified period of time."
}, {
  title: 'When will Call holder exercise his call?',
  subtitle: 'If the premium for the call option in the market is higher than the price at which the call was bought'
}, {
  title: 'Can the call holder sell the call before it expires?',
  subtitle: 'Yes, if the market pays him higher than his purchase price'
}, {
  title: 'Who coordinates the exercise of options and delivery of stock?',
  subtitle: "The Options Clearance Corporation."
}, {
  title: 'What happens if I do not close ITM call option before expiration?',
  subtitle: "If your options goes ITM and expires, the broker (by default) will exercise your contracts.."
}];
export const items2 = [{
  title: 'Moneyness of the option',
  subtitle: "Depending on the moneyness of the call, the holder will decide whether to exercise his right. In other words, Call must have an intrinsic value and a reasonable extrinsic value."
}, {
  title: 'Obligation to deliver',
  subtitle: ' If the right is exercised, the writer of the call must deliver 100 shares of stock. '
}, {
  title: 'Right to buy',
  subtitle: 'The call holder must be able to buy 100 shares if shares are assigned.'
}, {
  title: 'Margin Call',
  subtitle: "Margin calls are issued by options brokerages when either the buyer or seller of the call does not have sufficient funds.."
}];

const layoutProps = {
  meta,
ggbParameters,
opts,
Box1,
Box2,
DefaultBox,
Text4,
componetArray,
indexArray,
items1,
items2
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">










    <h1>{`Call EXERCISE and assignment scenarios`}</h1>

    <Container mdxType="Container">
  <Content items={items1} title={"Basics"} mdxType="Content" />
    </Container>
 <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/call_long_short_exercise_5.json" ggbParameters={ggbParameters} appId="DefinfitionVector2" email="pavanmirla@gmail.com" data={[{
      component: <Box1 mdxType="Box1" />,
      button: "button1"
    }, {
      component: <Box1 mdxType="Box1" />,
      button: "textn1"
    }, {
      component: <Box2 mdxType="Box2" />,
      button: "textn2"
    }]} mdxType="GeogebraDraw" />

    <Container mdxType="Container">
  <Content items={items2} title={"Here are the main points to remember"} mdxType="Content" />
    </Container>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;