
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import { useState } from 'react'
import Alert from '@mui/material/Alert';
import data from '../../data/options_chain.json';
import GeogebraComponent from "../../components/GeogebraComponent";
import bid_ask from '../../data/tulip_spot_trade.json';
import Content from "../../components/Content";
import Container from "../../components/Layouts/Container";
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
import Link from '@mui/material/Link';

export const opts = {
  height: "390",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    start: 5,
    end: 24
  }
};
export const short_position = data.data;
export const bid_ask_data = bid_ask.data;
export const short_position_params = {
  // material_id: "",
  // width: 1800,
  // height: 900,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  ggbBase64: short_position
};
export const bid_ask_params = {
  // material_id: "",
  // width: 1800,
  // height: 900,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  ggbBase64: bid_ask_data
};
export const meta = {
  title: " This is my Title2",
  chapter: "Chapter1",
  ispublished: true,
  date: "21 dec 2021",
  author: "Pavan Kumaar Mirla"
};
export const Highlight = ({
  children,
  color
}) => <span style={{
  backgroundColor: color,
  borderRadius: '2px',
  color: '#fff',
  padding: '0.2rem'
}}>
    {children}
  </span>;
export const ggbParameters = {
  // material_id: "",
  // width: 1800,
  // height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Box2 = () => <div style={{
  padding: 20,
  backgroundColor: 'green'
}} />;
export const DefaultBox = () => <div style={{
  padding: 20,
  backgroundColor: 'yellow'
}} />;
export const Text4 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const componetArray = [<DefaultBox mdxType="DefaultBox" />, <Box1 mdxType="Box1" />, <Box2 mdxType="Box2" />];
export const indexArray = {
  button1: 1,
  text14: 1
};
export const Figure = props => {
  return <figure class="chart">
      {
      /* <object data={props.data} type="image/svg+xml"></object> */
    }
      <img src={props.data} alt="yoast seo" height={props.height} width={props.width} />
      <figcaption>{props.caption}</figcaption>
    </figure>;
};
export const items1 = [{
  title: 'What is an Option Chain?',
  subtitle: 'The options chain is a matrix of available options contracts for a stock. '
}, {
  title: 'Where are the strikes ?',
  subtitle: 'Option strikes are comonly listed down the middle of the overall option chain'
}, {
  title: 'Picking a strike',
  subtitle: 'Picking a strike packs a lot of different trade-offs into a single number.Delta vs. Cost. Moneyness vs. Cost. Forecast vs. Expected Value.Implied Volatility.Exit Strategies '
}, {
  title: 'What is Ask price?',
  subtitle: 'Price at which someone will sell option to you'
}, {
  title: 'What is Bid price?',
  subtitle: 'Price at which someone will buy option from you'
}, {
  title: 'What is Open interest? Is this current information?',
  subtitle: "Remember that Open Interest is yesterday's news. Therefore, all the Open interest we see in Option chain is where the action used to be. "
}];
export const items2 = [{
  title: 'Never trade options as market orders. Use limit orders',
  subtitle: 'A wider bid and ask is dangerous for market orders.A seller might set a high Ask price trying to catch someone putting in a Market order instead of limit order. This is called fishing.'
}, {
  title: 'Wider Bid/Ask Spread',
  subtitle: 'Generally, a wider spread means a less liquid option. In order to get a fair price on that you should set your limit closer to the bid when opening and closer to the ask when selling'
}, {
  title: 'Can strike price get automatically adjusted?',
  subtitle: 'Strike price is adjusted when there is a special dividend(not announced nrmal dividends).Special dividends were unknown to happen, so the strikes themselves are adjusted to make up for the price that was not included.'
}];
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Typography = makeShortcode("Typography");
const layoutProps = {
  opts,
bid_ask_params,
meta,
Highlight,
ggbParameters,
Box1,
Box2,
DefaultBox,
Text4,
componetArray,
indexArray,
Figure,
items1,
items2
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">














    <h1>{`Option Chain`}</h1>

    <Container mdxType="Container">
  <Content items={items1} title={"Options Chain 101"} mdxType="Content" />
    </Container>
  <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/option_chain_activity.json" ggbParameters={ggbParameters} appId="id2" email="pavanmirla@gmail.com" data={[{
      component: <Box1 mdxType="Box1" />,
      button: "button1"
    }, {
      component: <Box2 mdxType="Box2" />,
      button: "text14"
    }, {
      component: <Text4 mdxType="Text4" />,
      button: "text4"
    }]} mdxType="GeogebraDraw" />

    <Container mdxType="Container">
  <Content items={items2} title={"Caution"} mdxType="Content" />
    </Container>
  <Container mdxType="Container">
   <Typography variant="subtitle1" gutterBottom component="div" color="gray" mdxType="Typography">
        References
      </Typography>
    <Typography variant="caption" display="block" gutterBottom color="gray" mdxType="Typography">
        Keith Cuthbertson, Dick Nitzsche,Niall Sillivan - Derivatives theory and Practice
        <br />
        <br />
    <Link href="https://www.reddit.com/r/options/comments/u7by3e/selecting_exp_and_strike/." mdxType="Link">
     {'Option strke'}
    </Link>
    <br />
        <Link href="https://www.reddit.com/r/options/comments/ueptx6/open_interest_spikes_on_certain_strike_prices/" mdxType="Link">
     {'Open interest'}
    </Link>
    <br />
    <Link href="https://www.reddit.com/r/options/comments/st6wno/my_covered_calls_strike_price_went_down/" mdxType="Link">
     {'Automatic Strike adjustment'}
    </Link>
    <br />
      </Typography>
    </Container>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;