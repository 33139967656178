import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactPlayer from 'react-player'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function ReactPlayerComp(props) {
  return (
    <div style={{padding:20, backgroundColor: 'tomato'}}>
    <ReactPlayer controls="true" width="100%" height="100%" url={props.url} />
  </div>
  );
}