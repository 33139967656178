import React from 'react'
import { useState } from 'react'
import './styles.css'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import { Document, Page, pdfjs } from 'react-pdf'
import useWindowDimensions from  '../UseWindowDimensions.js'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export default function App() {
    const [scale, setScale] = useState('1')
    const [pageNumber,SetPageNumber] = useState(1)
    const [totalPages,SetTotalPages] = useState(null)
    const { height, width } = useWindowDimensions();
    let scaleNumber = parseFloat(scale)
    if (Number.isNaN(scaleNumber)) scaleNumber = 1

    return (
        <div className="App">
        
            <div className="container">
                <Document  file="https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/poster_pages_short_straddle.pdf"
                       
                >
                    <Page scale={1} pageNumber={1} width={width}/>

                </Document>
      
            </div>
        </div>
    )
}
