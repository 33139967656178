
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import Content from "../../components/Content";
import Container from "../../components/Layouts/Container";
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
import VideoSection from "../../components/VideoSection";
export const meta = {
  title: " This is my Title2",
  chapter: "Chapter1",
  ispublished: true,
  date: "21 dec 2021",
  author: "Pavan Kumaar Mirla"
};
export const ggbParameters = {
  // material_id: "",
  // width: 1800,
  // height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const opts = {
  height: '390',
  width: '510',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="EfmTWu2yn5Q" opts={opts} mdxType="YouTube" />
  </div>;
export const Box2 = () => <div style={{
  padding: 20,
  backgroundColor: 'green'
}} />;
export const DefaultBox = () => <div style={{
  padding: 20,
  backgroundColor: 'yellow'
}} />;
export const Text4 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="HHKwnUa3txo" opts={opts} mdxType="YouTube" />
  </div>;
export const componetArray = [<DefaultBox mdxType="DefaultBox" />, <Box1 mdxType="Box1" />, <Box2 mdxType="Box2" />];
export const indexArray = {
  button1: 1,
  text14: 1
};
export const items1 = [{
  title: "What is covered call strategy? ",
  subtitle: "A covered call strategy is long stock + a short stock"
}, {
  title: "What is difference betweeen naked call write and covered call write??",
  subtitle: "When a trader sells a call, he is obliged to sell 100 shares of a stock at certain strike to the call holder, if the call is exercised. Calls written by someone who does not own stocks to deliver are known as naked calls. However, when he does own stocks to deliver, then such a call is called covered call."
}, {
  title: 'Obligation to deliver stock if call is exercised',
  subtitle: "If the call is exercised, call sellers must sell 100 shares of stock for each call contract sold. The call is likely exercised when the stock price is above the strike price, which means it is in the money for the holder.  "
}, {
  title: 'What is early assignment?',
  subtitle: "If the call holder wants to take ownership of the stock right away, he can exercise his right to purchase 100 shares of stock before expiry. Not before expiry. This could be for dividend purposes.  "
}, {
  title: 'Profit if option ends up worthless ',
  subtitle: "The call holder will not exercise the right to buy if the stock price is below the strike, therefore the shares will not be called away. The call writer keeps the premium collected while holding on to his shares.   "
}, {
  title: 'Closing covered call before expiration',
  subtitle: "To avoid the obligation to sell shares, a call writer might take an offsetting long call position in order to avoid capital gains if the call is exercised.    "
}, {
  title: 'Limited profits. Upside is limited',
  subtitle: "Max profit is capped and it depends on the collected premium. Max profit is the strike price (collected if the call was exercised and shares were called) minus the stock purchase price plus the collected premium"
}, {
  title: 'What happens to net delta after you sell a call while holding a stock',
  subtitle: "Delta os a stock only portfolio is 100. The net delta of covered call is lowered by negative delta of the sold call"
}, {
  title: 'volaitlity of stock',
  subtitle: "Since the strategy will profit if the call is not exercised, less volatilte stocks are likely more suitable for writing calls "
}, {
  title: 'Similar to short put. Synthetic equivalent',
  subtitle: "The payoff of covered call is equivalent to a written put (sold put) of same strike and same maturity plus cash equal to strike. This is due to put call parity.  "
}];
export const items2 = [{
  title: "Covered call before expiry and at expiry",
  subtitle: "Adjust the timer parameter on he left side panel to shange the time left to maturity. Observe the payoff of the short call over time"
}, {
  title: "Covered call Anatomy",
  subtitle: "Reset to defaults and see the details of eachy component of the Coveerd call strategy"
}, {
  title: "Stock price and PnL",
  subtitle: "Losses and profits can be investigated with stock price range chosen by moving the yellow traingle for stock price"
}, {
  title: "Embedded Quizzes",
  subtitle: "In each section, quizzes will help the reader to remember the concepts."
}];

const layoutProps = {
  meta,
ggbParameters,
opts,
Box1,
Box2,
DefaultBox,
Text4,
componetArray,
indexArray,
items1,
items2
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">










    <h1>{`Covered Call strategy`}</h1>

    <VideoSection video={"https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/covered_call.mp4"} mdxType="VideoSection" />
    <Container mdxType="Container">
  <Content items={items1} title={"Basics"} mdxType="Content" />
    </Container>
    <h1>{`Intro Explainer`}</h1>
  <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/covered_call_intro.json" ggbParameters={ggbParameters} appId="cc4" email="pavanmirla@gmail.com" data={[]} mdxType="GeogebraDraw" />
    <h1>{`Detailed Explainer`}</h1>
  <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/covered_call_2022_3.json" ggbParameters={ggbParameters} appId="cc1" email="pavanmirla@gmail.com" data={[{
      component: <Box1 mdxType="Box1" />,
      button: "button1"
    }, {
      component: <Text4 mdxType="Text4" />,
      button: "textn1"
    }, {
      component: <Text4 mdxType="Text4" />,
      button: "textn2"
    }]} mdxType="GeogebraDraw" />

    <Container mdxType="Container">
  <Content items={items2} title={"Explainer Highlights"} mdxType="Content" />
    </Container>
    <h3>{`Useful Links`}</h3>
    <a href="https://www.reddit.com/r/options/comments/t51sd4/anyone_ever_buy_back_covered_calls/" target="_blank">Buying back covered calls</a>
    <p>{`  `}<a parentName="p" {...{
        "href": "https://www.reddit.com/r/options/comments/tj56z7/have_570k_1900_shares_of_msft_pls_advice_on_good/"
      }}>{`Discussion`}</a>{`
`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;