
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import VerticalSteps from "../../components/VerticalSteps";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import ReactPlayer from 'react-player'
import Stack from '@mui/material/Stack';
import Button from "@material-ui/core/Button";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Form from "../../components/NetlifyForm";
import Content from "../../components/Content";
import Container from "../../components/Layouts/Container";
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
export const items1 = [{
  title: 'Why do options become cheaper close to expiry?',
  subtitle: "Theta or time decay decreases the extrinsic value of option over time, hence making long options cheaper with passge of time. Options with longer expiration dates are priced higher since there is a higher chance that the stock will move in their favor if there are more days left until the option expires. "
}, {
  title: 'Overall, my portfolio is negative theta. What does it mean?',
  subtitle: 'This indicates that the portfolio is net long, that is, long holdings exceed short holdings'
}, {
  title: "How does theta look for short positions?",
  subtitle: 'Theta is positive for option sellers. Theta increases for short posiitons as time reches maturity.You can think of short position holders as insurance companies that sell insurance, benefiting when time passes without a claim being made.  '
}];
export const items2 = [{
  title: 'Theta diminishes agressively ATM as time is close to expiry',
  subtitle: "It is because long call positions have maximum extrinsic value at the money. This is due to the fact that where the stock ends up from its center is not certain. Near expiration, this extrinsic value shrinks aggressively  "
}, {
  title: 'How are gamma and theta related?',
  subtitle: 'This indicates that the portfolio is net long, that is, long holdings exceed short holdings'
}, {
  title: 'What is effect of incread volatility on theta?',
  subtitle: 'Higher volatility means higher option prices. A higher level of uncertainty means a higher likelihood that stocks will move in a positive direction. With a higher extrinsic value, more theta will be lost over time. Theta is therefore high when volatility is high '
}];
export const ggbParameters = {
  // material_id: "",
  // width: "100%",
  // height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};

const layoutProps = {
  items1,
items2,
ggbParameters
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Theta for a long call`}</h1>

    <Container mdxType="Container">
  <Content items={items1} title={"Theta for long and short positions"} mdxType="Content" />
    </Container>
    <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/COVERED_CALL/greeks_3d_thets_2022_7.json" ggbParameters={ggbParameters} appId="appId1strends" email="pavanmirla@gmail.com" data={[]} mdxType="GeogebraDraw" /> 

    <Container mdxType="Container">
  <Content items={items2} title={"Expaliner highlights"} mdxType="Content" />
    </Container>

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;