import React, { useEffect, useState, useReducer } from "react";
import "../markdown-styles/styles.css"
import Typography from '@mui/material/Typography';





export default function ToolTipComponent(props) {
    return (
    <div  style={{ 
        margin: "auto",
        width: "50%",
        textAlign: "center",
        color: "lightsteelblue",
        marginTop: "20px",
        minHeight: "70px"	

        }}>
       {props!=null && <div>
         <div >

         <Typography variant="h5" style={{color:"#ce93d8"}} display="inline" >
          {props.mouseOverIndex.id}
         </Typography>
         <Typography variant="caption"  display="block" >
          <span> {props.mouseOverIndex.company}</span>
          <Typography variant="caption" style={{color:"#ffb74d"}} display="block" gutterBottom>
          {props.mouseOverIndex.industry}
         </Typography>
         <Typography variant="caption" style={{ marginRight: "10px"}} display="inline" gutterBottom>
             PerfWeek: {props.mouseOverIndex.PerfWeek}%
         </Typography>
          <span className="spacer">PerfMonth: {props.mouseOverIndex.PerfMonth}%</span>
          <span className="spacer">PerfYear: {props.mouseOverIndex.PerfYear}%</span>
          </Typography>
          </div>    
        </div>
       }
       </div>
      );


}
