import React from "react";

import { ResponsiveSwarmPlot, SwarmPlotAnnotations } from "@nivo/swarmplot";
import { useState } from "react";
import { PatternLines } from "@nivo/core";
import _ from "lodash";
import * as d3 from "d3";
import { SingleSelect } from "react-select-material-ui";
import Grid from '@mui/material/Grid';
import ToolTipComponent from "./ToolTipComponent"
import Typography from '@mui/material/Typography';

import {
  Annotation,
  SubjectCircle,
  ConnectorElbow,
  ConnectorEndDot,
  Note,
  AnnotationLabel
} from "react-annotation";

// import { Annotation, useAnnotations } from '@nivo/annotations'

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
// https://blog.logrocket.com/react-area-chart-comparison/ //
// without div styling, chart doesnt show up



const SP500SwarmPlot = ({
  data,
  valueVariable,
  groupVariable,
  options_sorted
}) => {
  const [currentIndex, setCurrentIndex] = useState(13);
  const [mouseOverIndex, setMouseOverIndex] = useState({});

  const groups_in = _.values([
    ...new Set(data.map((item) => item[groupVariable]))
  ]);


  function onChildClick(index) {
    setMouseOverIndex(index);
  }

  const extent = d3.extent(_.map(data, valueVariable));
  const min = d3.min(_.map(data, valueVariable));
  const max = d3.max(_.map(data, valueVariable));

  function handleChangeSingle(d) {
    // const action = this.onChange.arguments[1].action
    // const last_element = _.last(d).value
    const last_element = d;
    // const options_array = this.options
    const selected_index = 
    data.map(function (e) {
        return e.id;
      }).indexOf(last_element);
    
    setCurrentIndex(selected_index);
    const el = document.getElementById(last_element);
    const top = el.getBoundingClientRect().y - window.innerHeight / 2;
    const left = el.getBoundingClientRect().x;
    debugger
    // window.scrollTo({
    //   top: top,
    //   left: left,
    //   behavior: "smooth"
    // });
    el.scrollIntoView( {behavior: "smooth", block: "center", inline: "nearest" } );

}

  return (
    //height attribute is must
    <>
    <div style={{ width: "100%", zIndex: 6000, color: "gray", margin: 'auto', position: 'fixed', top: 0 , backgroundColor: "#1e1e1e"}}>
    <ToolTipComponent currentIndex={currentIndex} mouseOverIndex={mouseOverIndex}/>
    </div>


    <SingleSelect 
            style={{ width: 140, zIndex: 6000, color: "gray", margin: 'auto', position: 'fixed', top: 0 , backgroundColor: "lightsteelblue"}}
            // closeMenuOnSelect={true}
            // components={{ IndicatorsContainer }}
            // defaultValue={[options[0], options[1]]}
            label="Search Ticker"
            onChange={handleChangeSingle}
            options={options_sorted}
            // isMulti={true}
            // styles={colourStyles}
        
          /> 


    <div >
    <div
      // left={styles.left}
      style={{ maxWidth: "1200px", margin: "0 auto", height: "2700px", top: "75px" , position: 'relative', zIndex: 200}}
    >
      <ResponsiveSwarmPlot
        data={data}
        groups={groups_in}
        value={valueVariable}
        valueFormat="$.2f"
        valueScale={{ type: "linear", min: min, max: max, reverse: false }}
        size={{ key: valueVariable, values: extent, sizes: [20, 55] }}
        forcesStrength={4}
        simulationIterations={100}
        spacing={8}
        layout="horizontal"
        colors={{ scheme: "paired" }}
        borderColor={{ from: "color", modifiers: [["darker", 0.6]] }}
        borderWidth={2}
        enableGridX={true}
        margin={{ top: 160, right: 100, bottom: 80, left: 70 }}
        axisTop={{
          orient: "top",
          tickSize: 10,
          tickPadding: 5,
          tickRotation: 0,
          legend: valueVariable + "." + " % Change",
          legendPosition: "middle",
          legendOffset: -106
        }}
        tooltip={(slice) => {
          return (
            <div>
              <h2>{slice.node.id}</h2>
              <h2>{slice.node.group}</h2>
            </div>
          );
        }}
        // axisRight={{
        //   orient: "right",
        //   tickSize: 10,
        //   tickPadding: 5,
        //   tickRotation: 0,
        //   legend: "price if vertical, group if horizontal",
        //   legendPosition: "middle",
        //   legendOffset: 76
        // }}
        axisBottom={{
          orient: "bottom",
          tickSize: 10,
          tickPadding: 5,
          tickRotation: 0,
          legend: valueVariable + "." + " % Change",
          legendPosition: "middle",
          legendOffset: 46
        }}

        motionStiffness={50}
        motionDamping={10}
        layers={[
          "grid",
          "axes",
          backgroundLayer,
          "nodes",
        //   shadowsLayer,
        //   labelLayer,
          (props) => <CustomNodes {...props} onChildClick={onChildClick}/>, // this is enable basedon search
          (props) => <SearchHighlight {...props} currentIndex={currentIndex} />, // this is enable basedon search
          (props) => <backgroundLayerLeft {...props} min={min} />
        ]}
        tooltip={(slice) => {
            return (
              <div>
                <div> Company: {slice.data.Company} </div>
              </div>
            );
          }}
        theme={theme}
      />
    </div>
    </div>

    </>
  );
};

const theme = {
  background: "#1e1e1e",
  axis: {
    ticks: {
      line: {
        stroke: "lightgray"
      },
      text: {
        fill: "gray",
        fontWeight: 600
      }
    },
    legend: {
      text: {
        fill: "lightgray",
        fontSize: 15
      }
    }
  },
  grid: {
    line: {
      stroke: "rgb(128, 115, 172)",
      strokeDasharray: "2 4",
      strokeWidth: 2
    }
  }
};

const SearchHighlight = ({ nodes, margin, innerWidth, currentIndex }) => {
  const node = nodes[currentIndex];
  const radius = node.size;

  return (
    <g style={{ pointerEvents: "none" }} id = "searchId">
      <circle
        cx={node.x}
        cy={node.y}
        r={radius}
        fill="none"
        strokeWidth={4}
        stroke="red"
      />
    </g>
  );
};

const shadowsLayer = ({ nodes }) => {
    debugger
    return nodes.map(node => (
      <circle
        key={node.id}
        cx={node.x}
        cy={node.y + node.size * 0.05}
        r={node.size * 0.53}
        fill="rgba(30, 0, 40, .2)"
      />
    ));
  };

  const CustomNodes = (props) => {
    // you can use d.node here
    //group related code here
    return props.nodes.map(node => (
      <g
        id={node.id}
        transform={`translate(${node.x},${node.y})`}
        onMouseOver={(data, event) => {
        //   d.onChildClick(`${node.data.group}: ${node.data.Ticker} `);
        //   alert(`${node.data.id} ${node.data.group}`);
          props.onChildClick(
              {id:node.data.id,
               company: node.data.Company,
               industry: node.data.Industry,
               PerfWeek: node.data.PerfWeek,
               PerfMonth: node.data.PerfMonth,
               PerfYear: node.data.PerfYear
            })
        }}
      >
        {/* <circle r={d.node.size / 2} stroke="rgb(216, 218, 235)" strokeWidth={12} /> */}
        
        <circle
          r={node.size / 2.1}
          fill={node.data.fill_color}
          stroke={node.data.stroke_color}
          strokeWidth={2}
        />
  
          <text
            fill={node.data.font_color}
            textAnchor="middle"
            dominantBaseline="central"
            style={{
              fontSize: 10
              // fontWeight: "bold"
            }}
          >
            {node.data.id}
          </text>
    
      </g>
    ))
  }

  const labelLayer = ({ nodes }) => {
      debugger
    return nodes.map(node =>
      node.size ? (
        <text
          id={node.id}
          key={node.id}
          x={node.x}
          y={node.y}
          fill= {node.data.fill_color}
        //   fill="white"
          stroke="white"
          strokeOpacity={0.15}
        //   strokeWidth={3}
          //strokeLinejoin="bevel"
        //   fillOpacity={1}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            // fontSize: Math.max(node.size / 2.5, 10),
            fontSize: 12,
            fontWeight: 100,
            fontFamily: "Consolas",
            mixBlendMode: "color-dodge",
            mixBlendMode: "Consolas",
          }}
        >
          {node.data.Ticker}
        </text>
      ) : (
        ""
      )
    );
  };

  
// Note hre we will be usig fragments
const backgroundLayer = ({ xScale, innerHeight }) => {

  return (
    <>
      <defs>
        <PatternLines
          id="linesPattern"
          spacing={10}
          rotation={-45}
          background="rgb(199, 234, 229)"
          color="rgb(128, 205, 193)"
          lineWidth={1}
          opacity="0.3"
        />
      </defs>
      <rect
        x={xScale(0)}
        y={0}
        width={xScale(5000) - xScale(0)}
        height={innerHeight}
        fill="url(#linesPattern)"
        opacity="0.25"
      />
    </>
  );
};


// Note hre we will be usig fragments
const backgroundLayerLeft = ({ xScale, innerHeight, min }) => {
debugger
    return (
      <>
        <defs>
          <PatternLines
            id="linesPattern"
            spacing={10}
            rotation={-45}
            background="rgb(199, 234, 229)"
            color="rgb(128, 205, 193)"
            lineWidth={1}
            opacity="0.3"
          />
        </defs>
        <rect
          x={xScale(min)}
          y={0}
          width={xScale(0) - xScale(min)}
          height={innerHeight}
          fill="url(#linesPattern)"
          opacity="0.25"
        />
      </>
    );
  };


export default SP500SwarmPlot;
