
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import VerticalSteps from "../../components/VerticalSteps";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import ReactPlayer from 'react-player'
import Stack from '@mui/material/Stack';
import Button from "@material-ui/core/Button";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Form from "../../components/NetlifyForm";
import Content from "../../components/Content";
import Container from "../../components/Layouts/Container";
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
import ButtonMailto from "../../components/ButtonMailto.js";
import Link from '@mui/material/Link';
export const items1 = [{
  title: 'What is implied volatility?',
  subtitle: 'Implied volatility is the future volatility expected by the options market. Implied volatility is 1 standard deviaiton over whole year of continuously compounding returns. In Black Scholes formula Implied volatility is the only variable that is unknown. markets determine by the market. Black Scholes formula is applied to dollar price to give implied volatility '
}, {
  title: 'Implied volatility as a trading tool',
  subtitle: 'IV factors in all market expectations.IV value can be used to compare options and determine whether one is more expensive or less expensive. '
}, {
  title: 'What is realized volatility?',
  subtitle: 'Realized volatility is the volatility actually realized in the underlying market. Realized volatilityt can be estimated using historical returns over a certain period. Different methods to calculate realized volatility are discussed in latter sections'
}];
export const items3 = [{
  title: 'Inverting Theoretical Black Scholes equation ',
  subtitle: "Observed market value of call is input in to the BlackScholes instead of the regular approach to get call price as input. Then the volatility is adjusted until the calculated thoretical call price matches the market value of call"
}, {
  title: 'Market value of call used to imply future volatility',
  subtitle: "Reader can change the market price (pink circle) and then hit the estimate implied volatility method"
}, {
  title: 'How to use implied volatility?',
  subtitle: "If traders forecast for volatility is higher or lower than imlied volatility levels, he might consider selling expensive options, or buy cheaper options."
}];
export const rvol = [{
  title: 'What is realized volatility ',
  subtitle: "Observed market value of call is input in to the BlackScholes instead of the regular approach to get call price as input. Then the volatility is adjusted until the calculated thoretical call price matches the market value of call"
}, {
  title: 'Realized volatility as benchmark to analyze richness/cheapness of implied volatility',
  subtitle: "Though realized volatility is backwards looking, it can be used as a benchmark vol or reference vol when pricing implied volatility. "
}];
export const ggbParameters = {
  // material_id: "",
  // width: "100%",
  // height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const items4 = [{
  title: 'What is Volatility trading?',
  subtitle: "When a trader sells expensive options or buys cheaper options based on his volatility forecast, while maintaining a delta neutral portfolio, he is trading volatility. His portfolio is unaffected by changes in stock price."
}, {
  title: 'What is delta neutral portfolio?',
  subtitle: "A portfolio that is imune to small change sin stock is called elta neutral portfolio. For example, a long call and short a certain quantity stock based on delta of the option results in a net delta of zero. Hence a delta neutral portfolio"
}, {
  title: 'Straddle',
  subtitle: "Refer to the long and short Straddle explainers that is setup by holding calls and puts of same strike and similar maturity. Implied move is estimated value of ATM straddle"
}, {
  title: 'The buyer of the option would be considered long volatility. Why ?',
  subtitle: 'Her goal is to profit from an increase in volatility.She is looking for a higher volatility price.'
}, {
  title: 'The seller of the option would be considered short volatility. Why ?',
  subtitle: 'Her goal is to profit from an decrease in volatility.She is looking for a lower volatility price.'
}];
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Box = makeShortcode("Box");
const layoutProps = {
  items1,
items3,
rvol,
ggbParameters,
items4
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Options Volatility`}</h1>

    <Container mdxType="Container">
  <Content items={items1} title={"Volatility Basics"} mdxType="Content" />
    </Container>
    <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/call_2022_implied_volatility.json" ggbParameters={ggbParameters} appId="appId1margincall" email="pavanmirla@gmail.com" data={[]} mdxType="GeogebraDraw" /> 

    <Container mdxType="Container">
  <Content items={items3} title={"Explainer Takeaways"} mdxType="Content" />
    </Container>
    <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/log_normal_sd_iv.json" ggbParameters={ggbParameters} appId="appId1voliv" email="pavanmirla@gmail.com" data={[]} mdxType="GeogebraDraw" /> 

    <Container mdxType="Container">
  <Content items={rvol} title={"Realized volatility"} mdxType="Content" />
    </Container>
    <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/log_normal_sd_2.json" ggbParameters={ggbParameters} appId="appId1volhistoric" email="pavanmirla@gmail.com" data={[]} mdxType="GeogebraDraw" /> 
    <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/checklist_quiz_volatility.json" ggbParameters={ggbParameters} appId="appId1volquiz" email="pavanmirla@gmail.com" data={[]} mdxType="GeogebraDraw" /> 


    <Container mdxType="Container">
  <Content items={items4} title={"Related Topics"} mdxType="Content" />
    </Container>
 <Box sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: 'center'
    }} mdxType="Box">
              <ButtonMailto label="Email Me" mailto="mailto:office@21ifm.com?subject=I saw your demo app. Want to connect!&body=Thanks!" mdxType="ButtonMailto" />
  </Box>
    <Container mdxType="Container">
   <Typography variant="subtitle1" gutterBottom component="div" color="gray" mdxType="Typography">
        References
      </Typography>
    <Typography variant="caption" display="block" gutterBottom color="gray" mdxType="Typography">
        Keith Cuthbertson, Dick Nitzsche,Niall Sillivan - Derivatives theory and Practice
      </Typography>
      <br />
        <Link href="https://medium.com/swlh/the-realized-volatility-puzzle-588a74ab3896" mdxType="Link">
     {'Realized vol applications'}
    </Link>
    <br />
        <br />
        <Link href="https://medium.com/swlh/the-realized-volatility-puzzle-588a74ab3896" mdxType="Link">
     {'Realized vol applications'}
    </Link>
       <br />
        <Link href="https://www.realvol.com/VolFormula.htm" mdxType="Link">
     {'Realized vol formulas'}
    </Link>
   <br />
        <Link href="    https://www.ally.com/do-it-right/investing/what-is-implied-volatility/" mdxType="Link">
     {'Implied volatility in standard deviation terms'}
    </Link>
    </Container>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;