// add useContext
import React, { useContext } from "react";
import { firebaseAuth } from "../provider/AuthProvider";
import { withRouter } from "react-router-dom";

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import {
  useHistory
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));


const Signup = (props) => {
  const classes = useStyles();
  const history = useHistory()
  const { handleSignup, inputs, setInputs, errors } = useContext(firebaseAuth);

  const delay = ms => new Promise(res => setTimeout(res, ms));


  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("handleSubmit");
    //wait to signup
    await handleSignup();
    await delay(2000);
    if(window.localStorage.getItem('error') !== "noerror")
    {
      history.push("/")
    }
    else {
      history.push("/signin")
      // alert(window.localStorage.getItem('error'))
    }
    

    //push home
    // props.history.push("/");
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  // return (
  //   <form onSubmit={handleSubmit}>
  //     {/* replace the div tags with a form tag */}
  //     <p>Signup</p>
  //     {/* make inputs  */}
  //     <input
  //       onChange={handleChange}
  //       name="email"
  //       placeholder="email"
  //       value={inputs.email}
  //     />
  //     <input
  //       onChange={handleChange}
  //       name="password"
  //       placeholder="password"
  //       value={inputs.password}
  //     />
  //     <button>signup</button>
  //     {errors.length > 0
  //       ? errors.map((error) => <p style={{ color: "red" }}>{error}</p>)
  //       : null}
  //   </form>
  // );

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            {/* <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lname"
              />
            </Grid> */}
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
               Minimum password length is 6
             
            </Grid> 
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleSubmit}
          >
            Sign Up
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link href="/signin" to="/signin" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        {/* <Copyright /> */}
      </Box>
      <Box mt={5}>
            {errors.length > 0
             ? errors.map((error) => (
              <>
                <p style={{ color: "red" }}>{error}</p>
                {/* <Signup /> */}
              </>
            ))
          :null}
            </Box>
    </Container>
  );
};

export default withRouter(Signup);