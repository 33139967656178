
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import { useState } from 'react'
import Alert from '@mui/material/Alert';
import data from '../../data/tulip_spot_trade.json';
import GeogebraComponent from "../../components/GeogebraComponent";
import VideoSection from "../../components/VideoSection";
import Content from "../../components/Content";
import Container from "../../components/Layouts/Container";
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
export const opts = {
  height: "390",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    start: 5,
    end: 24
  }
};
export const short_position = data.data;
export const short_position_params = {
  // material_id: "",
  width: 1800,
  height: 900,
  borderColor: "#1E1E1E",
  enableShiftDragZoom: false,
  ggbBase64: short_position
};
export const meta = {
  title: " This is my Title2",
  chapter: "Chapter1",
  ispublished: true,
  date: "21 dec 2021",
  author: "Pavan Kumaar Mirla"
};
export const Highlight = ({
  children,
  color
}) => <span style={{
  backgroundColor: color,
  borderRadius: '2px',
  color: '#fff',
  padding: '0.2rem'
}}>
    {children}
  </span>;
export const ggbParameters = {
  // material_id: "",
  width: "100%",
  height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Box2 = () => <div style={{
  padding: 20,
  backgroundColor: 'green'
}} />;
export const DefaultBox = () => <div style={{
  padding: 20,
  backgroundColor: 'yellow'
}} />;
export const Text4 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const componetArray = [<DefaultBox mdxType="DefaultBox" />, <Box1 mdxType="Box1" />, <Box2 mdxType="Box2" />];
export const indexArray = {
  button1: 1,
  text14: 1
};
export const items1 = [{
  title: 'Why would someone buy a call option? ',
  subtitle: "A trader might buy a call on the stock to speculate on the upward move, or he might want to lock in the price of an asset that he believes will rise.If the stock does not move in the expected direction, his loss will be limited to the purchase price.The holder of a call option has the right, but not the obligation, to exercise the option to purchase the asset at a lower price when the stock is higher at that time.  "
}, {
  title: 'Describe intrinsic value of a call option',
  subtitle: 'The option is in the money if the stock price is higher than the strike price on which it was purchased. In other words, the call holder can purchase the stock at a lower price than the higher market price. The intrinsic value of the call is the amount by which the stock is above strike at expiry. Keep in mind that we are discussing value at expiration.'
}, {
  title: 'What does it mean to be out of money?',
  subtitle: 'When the stock price is lower than the call options exercise price, a holder has no incentive to exercise the right to buy at the higher strike if the stock can be bought at a lower price. Therefore, in this situation, a call option is worthless. In other words, it has no value. '
}, {
  title: 'How is the call price determined?',
  subtitle: 'Option prices are determined by factors such as supply and demand for the option, volatility of the underlying, time left to expiry, and interest rates. This is calculated using Black Scholes framework that will be discussed in the upcoming chapters. '
}];
export const items2 = [{
  title: 'Describe who a call seller typically is. Why do they sell calls? ',
  subtitle: "The sellers of call contracts typically own stocks that they can deliver to the buyer of the call when the buyer exercises the right to buy assets from the call holder. When the call seller owns the asset to deliver, it is known as a covered call. If the call seller sold the call without holding the underlying assets, the call is called a naked call.Call sellers colect premium for solling call options. The premium is retained if the stock remains below the strike price, so the call is not exercised. On already owned assets, it generates additional income."
}, {
  title: 'What happens when call option is exercised by call buyer?  ',
  subtitle: "As a call seller, you must deliver 100 shares for each option sold. If you have 100 shares, the broker will deliver them to the buyer. Call sellers who do not hold 100 shares are net short 100 shares. Revisit this concept. The call seller is short 100 shares when the call is exercised, and the seller does not own 100 shares"
}];
export const items3 = [{
  title: 'According to what I read, call option values against the stock are curved, rather than linear. Why does the visual not reflect this? ',
  subtitle: "There is indeed a non  linear relationship between the option premium and stock prices before expiry. At expiry the value of call is the intrinsic value. A curved relationship is captured by black scholes options pricing, which will be discussed in the next section. I chose to keep it simple by discussing the options payoff at the expiration date in this introductory."
}, {
  title: 'The intrinsic value formulas for calls and sellers seem similar to me. Is that correct? ',
  subtitle: "Have you noticed the minus sign for intrinsic value for call sellers? The payoffs for the call buyer and call seller are mirror images."
}];
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Intro = makeShortcode("Intro");
const layoutProps = {
  opts,
short_position_params,
meta,
Highlight,
ggbParameters,
Box1,
Box2,
DefaultBox,
Text4,
componetArray,
indexArray,
items1,
items2,
items3
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">













    <h1>{`What are Options?`}</h1>
    <p>{`Options are a type of derivative. `}</p>
    <VideoSection video={"https://storage.googleapis.com/chapters-ifm/testfolder/2022/what_are_options_cow2.mp4"} mdxType="VideoSection" />
    <p>{`A derivative is a financial instrument whose value is derived from the value of another asset: the underlying. `}</p>
    <h1>{`what are call options?`}</h1>
    <p>{`An Call option gives the option owner the right, but not the obligation, to buy or
sell the underlying asset at a specified price any time during a designated
period or on a specified date. `}</p>
    <p>{`With call options, traders can :`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Speculate of the direction of stock `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Can earn credit by selling call, on held underlying`}</p>
      </li>
    </ul>
    <h1>{`Owning a call option is like owning a coupon`}</h1>
    <VideoSection video={"https://storage.googleapis.com/chapters-ifm/testfolder/2022/options_intro/pzza_video_payoff_goal_oriented.mp4"} mdxType="VideoSection" />
    <h1>{`call intrinsic value and profit graphs`}</h1>

    <Container mdxType="Container">
  <Content items={items1} title={"Call Buyer"} mdxType="Content" />
    </Container>

    <Container mdxType="Container">
  <Content items={items2} title={"Call Seller"} mdxType="Content" />
    </Container>
  <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/calls_parity_long_short_v1.json" ggbParameters={ggbParameters} appId="parity_grap_call" email="pavanmirla@gmail.com" data={[{
      component: <Intro mdxType="Intro" />,
      button: "text11"
    }, {
      component: <Text4 mdxType="Text4" />,
      button: "text3"
    }]} mdxType="GeogebraDraw" />

    <Container mdxType="Container">
  <Content items={items3} title={"Questions to ask"} mdxType="Content" />
    </Container>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;