
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import ReactPlayer from 'react-player'
import Content from "../../components/Content";
import Container from "../../components/Layouts/Container";
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
import VideoSection from "../../components/VideoSection";
export const items1 = [{
  title: 'What is Time spread? ',
  subtitle: "Time spreads involve options of same underlying stock, the same type, but different expiration months"
}, {
  title: 'What is a Calendar spread?',
  subtitle: 'This is a time spread with same strikes. They involve same underlying, same stock, same strike but different expiration months'
}, {
  title: 'What is a Diagonal spread?',
  subtitle: 'This is a time spread with different strikes'
}, {
  title: 'Calendar spread as a Neutral strategy',
  subtitle: 'Opt for calendar spread.Trying to benefit from time decay'
}, {
  title: 'Non linear Risk profile',
  subtitle: 'Since options with different expirations are used, risk profile/payoff is not linear'
}, {
  title: 'Other namess',
  subtitle: 'Since strategy collects net credit, it is common to call it a short time spread or short hotizontal spread'
}];
export const meta = {
  title: " This is my Title2",
  chapter: "Chapter1",
  ispublished: true,
  date: "21 dec 2021",
  author: "Pavan Kumaar Mirla"
};
export const ggbParameters = {
  // material_id: "",
  // width: 1700,
  // height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const opts = {
  height: '390',
  width: '510',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Intro = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4' mdxType="ReactPlayer" />
  </div>;
export const Text4 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const indexArray = {
  button1: 1,
  text14: 1
};
export const items2 = [{
  title: 'Profit from high volatility',
  subtitle: "Like long straddles and long strangles, short calendar spreads profit from high volatility since the maximum profit is realized either deep in the money or far out of money region"
}, {
  title: 'Less capital',
  subtitle: 'Short calendar spreads requires less capital, has smaller limited risk and has limited profit potential'
}];
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Typography = makeShortcode("Typography");
const Link = makeShortcode("Link");
const layoutProps = {
  items1,
meta,
ggbParameters,
opts,
Box1,
Intro,
Text4,
indexArray,
items2
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Short Time spread`}</h1>

    <Container mdxType="Container">
  <Content items={items1} title={"Basics"} mdxType="Content" />
    </Container>







    <h1>{`Credit Calendar Spread with Puts`}</h1>
    <p>{`Tarders bet on this strategy expecting big term stocks movement`}</p>
  <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/short_calendar_spread_put_trade.json" ggbParameters={ggbParameters} appId="id3" email="pavanmirla@gmail.com" data={[{
      component: <Intro mdxType="Intro" />,
      button: "text11"
    }, {
      component: <Text4 mdxType="Text4" />,
      button: "text3"
    }]} mdxType="GeogebraDraw" />

    <Container mdxType="Container">
  <Content items={items2} title={"Explainer Highlights"} mdxType="Content" />
    </Container>
  <Container mdxType="Container">
   <Typography variant="subtitle1" gutterBottom component="div" color="gray" mdxType="Typography">
        References
    </Typography>
    <Link href="https://www.fidelity.com/learning-center/investment-products/options/options-strategy-guide/short-calendar-spread-puts" mdxType="Link">
     {'Options Guide Fidelity'}
    </Link>
    <br />
    </Container>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;