import React from "react";

import * as posts from "./posts";

export default () => [
  {
    name: "OPTIONS VOLATILITY",
    id: "options_volatility",
    Component: posts.Volatility_Intro,
    video: "https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/implied_vol.mp4",
    image:
    "",
    description:
      "Using exponentially weighted moving average method for Volatility smoothing ",
    active: 1,
    category: "featured_pro",
    level: "P"
  },
  {
    name: "SHORT/CREDIT IRON CONDORS",
    id: "short_condor_full",
    Component: posts.Volatility_Condor_Full,
    image:
    "",
    video: "https://storage.googleapis.com/chapters-ifm/testfolder/2022/COVERED_CALL/iron_condor_home.mp4",
    description:
      "Similar to Strangles. Collect premium.Limiting risk. capping profits",
    active: 1,
    category: "featured_pro",
    level: "P"
  },

  {
    name: "Margin for Call Seller",
    id: "margin_call_seller",
    Component: posts.Margin_Call_Options,
    image:
    "",
    video: "https://storage.googleapis.com/chapters-ifm/testfolder/2022/COVERED_CALL/margin_home.mp4",
    description:
      "Maintenance margin, Initial margin requirements for basic to advanced strategies",
    active: 1,
    category: "featured_pro",
    level: "P"
  },
  {
    name: "PUT DELTA",
    id: "put_delta",
    Component: posts.LPDelta2D,
    video: "",
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/put_delta_ls.png",
    description:
      "Put premium change based on stock price. Delta Neutral portfolio. Delta for long/short put",
    active: 1,
    category: "featured_beginner",
    level: "P"
  },
  {
    name: "CALL ASSIGNMENT AND EXERCISE",
    id: "call_buyer_seller",
    Component: posts.Calls_basic_buyer_seller,
    video: "",
    image:
      "https://storage.googleapis.com/chapters-ifm/testfolder/strategies/desktop_call_buyer-seller.png",
    description:
      "Payoffs for buyers and sellers of a call option. Right and Obligations. Scenarios for exercise",
    active: 1,
    category: "featured_beginner",
    level: "P"
  },
  {
  
    name: "THETA SURFACE FOR LONG/SHORT CALL.",
    id: "theta_3d",
    Component: posts.LCTheta3D,
    image:
    "",
   video : "https://storage.googleapis.com/chapters-ifm/testfolder/2022/COVERED_CALL/heta_home.mp4",
    description:
      "Resembles a Trampoline for long Call.Sensitivity to implied volatilities, time, and rates",
    active: 1,
    category: "featured_beginner",
    level: "P"
  },

  {
    name: "CALL OPTIONS AT EXPIRY",
    id: "call_intro",
    Component: posts.IntroCall,
    image: "",
    video :"https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/call_intro.mp4",
    description:
      "What are Call Options? Why use Call Options?  Parity and payoff graphs. Seller and Buyer. Intrinsic Value at expiry.",
    active: 1,
    category: "call_intro",
    level: "P"
  },

  {
    name: "OPTIONS CHAIN",
    id: "options_chain",
    Component: posts.OptionsChain,
    image:
      "https://storage.googleapis.com/chapters-ifm/testfolder/calls/options_chain.png",
      video: "",
    description:
    "Option chain structure. Bid and Asks for calls and Puts. Market value, noneyness, greeks, open interest,implied volatility ",
    active: 1,
    category: "call_intro",
    level: "P"
  },
  {
    name: "CALL OPTION EXTRINSIC VALUE DRIVERS",
    id: "calls_ev",
    Component: posts.Calls_basic_EV,
    image:
      "https://storage.googleapis.com/chapters-ifm/testfolder/calls/long_call.png",
    video: "",
    description:
      "Right to buy an asset at a fixed price. But not obligated. Theoretical price is simulated in real time. Not just hockey stick charts",
    active: 1,
    category: "call_intro",
    level: "P"
  },
  {
    name: "CALL ASSIGNMENT AND EXERCISE",
    id: "call_buyer_seller",
    Component: posts.Calls_basic_buyer_seller,
    video: "",
    image:
      "https://storage.googleapis.com/chapters-ifm/testfolder/strategies/desktop_call_buyer-seller.png",
    description:
      "Payoffs for buyers and sellers of a call option. Right and Obligations. Scenarios for exercise",
    active: 1,
    category: "call_intro",
    level: "P"
  },
  {
    name: "SPECULATION WITH CALLS",
    id: "calls_speculator",
    Component: posts.Calls_speculator,
    image:
      "https://storage.googleapis.com/chapters-ifm/testfolder/calls/long_call.png",
    video: "",
    description:
      "Right to buy an asset at a fixed price. But not obligated. Theoretical price is simulated in real time. Not just hockey stick charts",
    active: 1,
    category: "call_intro",
    level: "P"
  },
  {
    name: "DELTAS FORMULA",
    id: "taylor_delta_gamma",
    Component: posts.Taylor_delta_Gamma,
    video: "",
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/calls/taylor_series.png",
    description:
      "Find average Gamma, Gamma PNL for Long call. Application of Taylor polynomial",
    active: 1,
    category: "call_intro",
    level: "P"
  },


  {
    name: "CALL DELTA",
    id: "call_delta",
    Component: posts.LCDelta2D,
    video: "",
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/call_delta_ls.png",
    description:
      "Call premium change based on stock price. Delta as hedge ratio, probability of ITM",
    active: 1,
    category: "call_intro",
    level: "P"
  },
  {
    name: "CALL DELTA CHANGES",
    id: "call_delta_sensitivity",
    Component: posts.LCDelta3DSensitivity,
    video: "https://storage.googleapis.com/chapters-ifm/testfolder/basics/delta_3d.mp4",
    image:
    "",
    description:
      "Delta in relation to volatility, moneyness and time in 3D",
    active: 1,
    category: "call_intro",
    level: "P"
  },
  {
    name: "CALL GAMMA",
    id: "gamma_3d",
    Component: posts.LCGamma3D,
    image:
    "",
   video : "https://storage.googleapis.com/chapters-ifm/testfolder/videos/gamma_intro.mp4",
    description:
      "Rate of change of delta. Gamma in relation to price changes, volatility, and time",
    active: 1,
    category: "call_intro",
    level: "P"
  }, 
  {
    name: "GAMMA SQEEZE",
    id: "gamma_squeeze",
    Component: posts.GammaSqueeze,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/calls/gamma_sqeeze.png",
    video: "",
    description:
      "Gamma explosion could be profitable. Conditions required for big Gamma changes",
    active: 1,
    category: "call_intro",
    level: "P"
  },
  {
    name: "CALL THETA",
    id: "theta_2d",
    Component: posts.LCTheta2D,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/strategies/desktop_theta.png",
   video : "",
    description:
      "Call premium decays over time. Theta in relation to volatility and stock",
    active: 1,
    category: "call_intro",
    level: "P"
  }, 
  {
  
    name: "THETA SURFACE FOR LONG/SHORT CALL.",
    id: "theta_3d",
    Component: posts.LCTheta3D,
    image:
    "",
   video : "https://storage.googleapis.com/chapters-ifm/testfolder/2022/COVERED_CALL/heta_home.mp4",
    description:
      "Resembles a Trampoline for long Call.Sensitivity to implied volatilities, time, and rates",
    active: 1,
    category: "call_intro",
    level: "P"
  },
  {
  
    name: "CALL VEGA",
    id: "calls_vega",
    Component: posts.LCVega2D,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/2022/vega.png",
   video : "",
    description:
      "Changes in call premium based on volatility",
    active: 1,
    category: "call_intro",
    level: "P"
  },
  

  {
    name: "ALL GREEKS FOR A CALL",
    id: "all_greeks_call",
    Component: posts.Greeks_Call,
    video: "",
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/strategies/deskitop_call.png",
    description:
      "Sensitivity of call premium to stock, volatility, time and rates ",
    active: 1,
    category: "call_intro",
    level: "P"
  },
  {
    name: "LINEAR FUNCTION",
    id: "linear_function",
    Component: posts.LinearFunction,
    image: "",
    video :"https://storage.googleapis.com/chapters-ifm/testfolder/math/linear_func.mp4",
    description:
      "Fundamentals of gradients and slope to calculate payoffs and changes in option premium",
    active: 1,
    category: "math",
    level: "P"
  },
  {
    name: "QUADRATIC FUNCTION",
    id: "quadratic_function",
    Component: posts.QuadraticFunction,
    image: "",
    video :"https://storage.googleapis.com/chapters-ifm/testfolder/math/quad_func.mp4",
    description:
      "Estimate convexity, concavity to analyze risks of changing volatility, time and stock price",
    active: 1,
    category: "math",
    level: "P"
  },
  {
    name: "WHY LOG RETURNS",

    id: "log-returns",
    Component: posts.LogReturns,
    image: "",
    video :"https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/log_returns_trailer.mp4",
    description:
      "Financial assets modlling with lognormal distribution.It's relation to normal distribution",
    active: 1,
    category: "math",
    level: "P"
  },
  {
    name: "LOGNORMAL DISTRIBUTION",

    id: "lognormal_normal",
    Component: posts.Normal_LogNormal_relationship,
    image: "",
    video :"https://storage.googleapis.com/chapters-ifm/testfolder/math/log_normal.mp4",
    description:
      "Financial assets modlling with lognormal distribution.It's relation to normal distribution",
    active: 1,
    category: "math",
    level: "P"
  },
  {
    name: "EXPONENTAIL FUNCTIONS",
    id: "exponential_function",
    Component: posts.Exponential_Logarithm_basics,
    image: "",
    video :"https://storage.googleapis.com/chapters-ifm/testfolder/math/exponential_functions.mp4",
    description:
      "Natural log, Exponential and linear functions. Special number e",
    active: 0,
    category: "math",
    level: "P"
  },
  {
    name: "BINOMIAL DISTRIBUTION",
    id: "binomial_dist",
    Component: posts.GaltonBoard_Binomial,
    image: "",
    video :"https://storage.googleapis.com/chapters-ifm/testfolder/math/binomial_func.mp4",
    description:
      "Applicable in pricing options with a multistep Binomial Model",
    active: 1,
    category: "math",
    level: "P"
  },
  {
    name: "CUMULATIVE DISTRIBUTION ",
    id: "cumulative_dist",
    Component: posts.Cumulative_Dist_Stock,
    image: "",
    video :"https://storage.googleapis.com/chapters-ifm/testfolder/math/density_functions.mp4",
    description:
      "Estimate probability of moneynes using standard normal cumulative distribution",
    active: 1,
    category: "math",
    level: "P"
  },
  {
    name: "PRICING FRAMEWORK",
    id: "pricing_framework",
    Component: posts.Pricing_Framework,
    image: "https://storage.googleapis.com/chapters-ifm/testfolder/basics/pricing_fw.png",
    video :"",
    description:
      "Estimate expected future value of risk asset. Consider risks and opportunity costs",
    active: 1,
    category: "pricing",
    level: "P"
  },
  {
    name: "BLACK SCHOLES FRAMEWORK ",
    id: "black_scholes",
    Component: posts.Black_Scholes_Simulation,
    image: "https://storage.googleapis.com/chapters-ifm/testfolder/math/desktop_nd1.png",
    video :"",
    description:
      "Analysis of all variables and assumptions in Black Scholes pricing",
    active: 1,
    category: "pricing",
    level: "P"
  },

  {
    name: "BLACK SCHOLES SOLUTION ",
    id: "black_scholes_solution",
    Component: posts.Black_Scholes_Density,
    image: "https://storage.googleapis.com/chapters-ifm/testfolder/basics/black_scholes_mathias.png",
    video :"",
    description:
      "Black Scholes formula d1, d2, N(d1) and N(d2) explained for calls and puts",
    active: 1,
    category: "pricing",
    level: "P"
  },
  

  {
    name: "BINOMIAL FRAMEWORK",
    id: "binomial_pricing",
    Component: posts.Binomial_one,
    image: "https://storage.googleapis.com/chapters-ifm/testfolder/basics/Binomial_matthias.png",
    video :"",
    description:
      "Pricing of call option example with Binomial framework for option pricing",
    active: 1,
    category: "pricing",
    level: "P"
  },
  {
    name: "PUT OPTIONS AT EXPIRY",
    id: "put_intro",
    Component: posts.IntroPut,
    image: "",
    video :"https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/put_intro.mp4",
    description:
      "What are Put Options? Why use Put Options? Parity and payoff graphs. Seller and Buyer. Intrinsic Value at expiry.",
    active: 1,
    category: "puts",
    level: "P"
  },
  {
    name: "PUT OPTION EXTRINSIC VALUE DRIVERS",
    id: "puts_ev",
    Component: posts.Puts_ev,
    image:
      "https://storage.googleapis.com/chapters-ifm/testfolder/calls/puts_long.png",
      video: "",
    description:
    "Right to sell an asset at a fixed price. Similar to insurance. Price, Volatility and time are simulated ",
    active: 1,
    category: "puts",
    level: "P"
  },

 
  {
    name: "PUT EXERCISE",
    id: "puts_buyer_seller_block",
    Component: posts.Puts_buyer_seller,
    image:
      "",
    video : "https://storage.googleapis.com/chapters-ifm/testfolder/videos/PUTS_CASH_SECURED.mp4",
    description:
      "Payoffs for buyers and sellers of a put option",
    active: 0,
    category: "puts",
    level: "P"
  }, 

  {
    name: "PUT ASSIGNMENT AND EXERCISE",
    id: "puts_buyer_seller",
    Component: posts.Puts_Seller_Buyer,
    video: "",
    image:
      "https://storage.googleapis.com/chapters-ifm/testfolder/2022/put_seller_buyer.png",
    description:
      "Payoffs for buyers and sellers of a put option.Right and Obligations. Scenarios for exercise",
    active: 1,
    category: "puts",
    level: "P"
  },
  {
    name: "PROTECTIVE PUT ",
    id: "protective_put",
    Component: posts.Puts_ptotective_put,
    video: "",
    image:
      "https://storage.googleapis.com/chapters-ifm/testfolder/2022/put_seller_buyer.png",
    description:
      "Payoffs for buyers and sellers of a put option.Right and Obligations. Scenarios for exercise",
    active: 1,
    category: "puts",
    level: "P"
  },
  {
  name: "SYNTHETICS",
  id: "put_call_parity",
  Component: posts.Put_Call_Parity,
  image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/calls/put_call_parity.png",
    video: "",
  description:
  "Replicate asset features by combining multiple options or underlying",
  active: 1,
  category: "synthetics",
  level: "P"
},
{
  name: "PUT CALL PARITY ARBITRAGE",
  id: "put_call_parity_arbitrage",
  Component: posts.Put_Call_Parity_Arbitrage,
  image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/arbitratr_cat.png",
    video: "",
  description:
  "This is a way to make money without investing when put call parity does not hold. Ever heard of free lunch?",
  active: 1,
  category: "synthetics",
  level: "P"
},

  // {
  //   name: "ALL GREEKS FOR LONG STRADDLE",
  //   id: "all_greeks_straddle",
  //   Component: posts.Straddle_Greeks,
  //   video: "",
  //   image:
  //   "https://storage.googleapis.com/chapters-ifm/testfolder/strategies/deskitop_call.png",
  //   description:
  //     "Sensitivity of call price to defined parameters",
  //   active: 1,
  //   category: "greeks_combo",
  //   level: "P"
  // },

  {
    name: "OPTIONS VOLATILITY",
    id: "options_volatility",
    Component: posts.Volatility_Intro,
    video: "https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/implied_vol.mp4",
    image:
    "",
    description:
      "Using exponentially weighted moving average method for Volatility smoothing ",
    active: 1,
    category: "volatility_intro",
    level: "P"
  },
  {
    name: "VOLATILITY SMOOTHING",
    id: "ewma",
    Component: posts.EWMA,
    video: "",
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/2022/vol_ewma.png",
    description:
      "Using exponentially weighted moving average method for Volatility smoothing ",
    active: 1,
    category: "volatility_intro",
    level: "P"
  },

 
  {
    name: "BEARISH PUT SPREAD",
    id: "debit_puts_spread",
    Component: posts.Bearish_Put_Spread,
    image:
      "",
      video: "https://storage.googleapis.com/chapters-ifm/testfolder/math/bearish_long_put.mp4",
    description:
    "Learn about enterign trade, calculating profits and losses and volatility impact on this bearish outlook trade   ",
    active: 1,
    category: "vol_rise_price_bearish",
    level: "P"
  },
  {
    name: "BULLISH CALL SPREAD / DEBIT",
    id: "bullish_debit_spread",
    Component: posts.BullishLongCall,
    video: "",
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/2022/long_call_spread.png",
    description:
      "Sell higher strike call, Buy low strike call Spread.Setup in low vol environment.Net Debit. All greeks",
    active: 1,
    category: "vol_drop_price_bearish",
    level: "P"
  },
  {
    name: "BEARISH CALL SPREAD",
    id: "bearish_call_spread",
    Component: posts.Bearish_Call_Spread,
    image:
      "",
      video: "https://storage.googleapis.com/chapters-ifm/testfolder/2022/bearish_spread.mp4",
    description:
    "Bearish spread with two Call posiitons. delta, Gamma, Vega, Theta fopr the spread strategy  ",
    active: 1,
    category: "strategy_bullish_bearish",
    level: "P"
  },

  {
    name: "BINARY EVENT. DEBIT STRADDLE",
    id: "straddle_debit",
    Component: posts.Straddle_Basic,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/2022/Straddle_Basic.png",
    video: "",
    description:
      "Introduction to setting up a long Straddle (a debit trade)",
    active: 1,
    category: "strategy_vol",
    level: "P"
  },

  {
    name: "INSURANCE SELLER. CREDIT STRADDLE",
    id: "straddle_credit",
    Component: posts.Volatility_Straddle,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/2022/short_straddle/short_straddle.png",
    video: "",
    description:
      "Voaltility high environment to enter. Expecting a calm stock price action",
    active: 1,
    category: "strategy_vol",
    level: "P"
  },

  {
    name: "CREDIT STRANGLE",
    id: "strangle_credit",
    Component: posts.Volatility_Strangle,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/2022/strangle.png",
    video: "",
    description:
      "Voaltility high environment to enter. Expecting a calm stock price action. Risk of big stock move",
    active: 1,
    category: "strategy_vol",
    level: "P"
  },
  {
    name: "STOCK REPAIR WITH BULL CALL RATIO",
    id: "ratio_spread_neutral",
    Component: posts.Bullish_Call_Ratio,
    image:
    "",
    video: "https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/ratio_spread.mp4",
    description:
      "Bullish, Expecting neutral to slighlty bullish movement in stock. Gain with Time decay and volaitlity fall",
    active: 1,
    category: "strategy_vol",
    level: "P"
  },

  {
    name: "PUT RATIO BACKSPREAD. EXPECTING DRASTIC DOWNTURN",
    id: "put_ratio_backspread",
    Component: posts.Bearish_Put_Ratio,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/strategies/laptop_put_ratio_back_spread.png",
    video: "",
    description:
      "Bearish, Expecting a breakdown in stock. Reserved for experienced speculators",
    active: 1,
    category: "strategy_vol",
    level: "P"
  },

  {
    name: "SHORT/CREDIT  BUTTERFLY",
    id: "short_butterfly",
    Component: posts.Volatility_Butterfly,
    image:
    "",
    video: "https://storage.googleapis.com/chapters-ifm/testfolder/2022/test.mov",
    description:
      "The maximum profit is the amount of premium collected by writing the options.",
    active: 1,
    category: "strategy_vol",
    level: "P"
  },
  {
    name: "SHORT/CREDIT IRON CONDORS",
    id: "short_condor",
    Component: posts.Volatility_Condor,
    image:
    "",
    video: "https://storage.googleapis.com/chapters-ifm/testfolder/2022/COVERED_CALL/iron_condor_home.mp4",
    description:
      "Similar to Strangles. Collect premium.Limiting risk. capping profits",
    active: 1,
    category: "strategy_vol",
    level: "P"
  },
  {
    name: "SHORT/CREDIT IRON CONDORS",
    id: "short_condor_full",
    Component: posts.Volatility_Condor_Full,
    image:
    "",
    video: "https://storage.googleapis.com/chapters-ifm/testfolder/2022/COVERED_CALL/iron_condor_home.mp4",
    description:
      "Similar to Strangles. Collect premium.Limiting risk. capping profits",
    active: 1,
    category: "strategy_vol",
    level: "P"
  },
  {
    name: "SHORT STRADDLE. EXPECTING MARKET TO STAGNATE",
    id: "short_straddle",
    Component: posts.Neutral_Straddle,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/calls/short_straddle.png",
    video: "",
    description:
      "Expecting minimal price movement.The maximum profit is the amount of premium collected by writing the options.",
    active: 0,
    category: "strategy_vol",
    level: "P"
  },
  {
    name: "LONG PUT CALENDAR SPREAD",
    id: "put_long_calendar_spread",
    Component: posts.Bearish_Long_Put_Calendar,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/calls/calendar.gif",
    video: "",
    description:
      "Expecting miminal price movement. Reserved for experienced speculators",
    active: 1,
    category: "strategy_time",
    level: "P"
  },
  {
    name: "SHORT CALENDAR SPREAD",
    id: "short_calendar_spread",
    Component: posts.Short_Put_Calendar,
    image:
    "",
    video: "https://storage.googleapis.com/chapters-ifm/testfolder/2022/COVERED_CALL/iron_condor_home.mp4",
    description:
      "Horizontal spreads. Combining options with different maturities",
    active: 1,
    category: "strategy_time",
    level: "P"
  },
  {
    name: "STATIC HEDGE WITH COVERED CALL",
    id: "covered_call",
    Component: posts.CoveredCalls,
    image: "https://storage.googleapis.com/chapters-ifm/testfolder/2022/covered_call.png",
    video: "",
    description:
      "Generate additonal income from existing stock prosition.",
    active: 1,
    category: "strategy_hedging",
    level: "P"
  },

  {
    name: "POOR MAN'S COVERED CALL",
    id: "poor_man_cc",
    Component: posts.PoorMansCoveredCalls,
    image: "https://storage.googleapis.com/chapters-ifm/testfolder/calls/covered_call.png",
    video: "",
    description:
      "Similar to Covered call. Synthetic stock posiiton created",
    active: 1,
    category: "strategy_hedging",
    level: "P"
  },

  {
    name: "WHEEL STRATEGY TO COLLECT PREMIUMS",
    id: "wheel_strategy",
    Component: posts.WheelStrategy,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/strategies/cash_secured_puts_wheel.png",
    video: "",
    description:
      "Cash Secured Puts and Straddles. Collect premiums",
    active: 1,
    category: "strategy_hedging",
    level: "P"
  },

  {
    name: "PinRisk, Taxes",
    id: "pinrisk",
    Component: posts.Risk_management,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/calls/neiderhoffer.png",
    video: "",
    description:
      "Risk management and lessons from History covering Nick Leesen, Victor Niederhoffer ",
    active: 1,
    category: "risk_management"
  },
  {
    name: "Margin Call on Stocks",
    id: "margincall_stocks",
    Component: posts.Margin_Call_Stocks,
    image:
    "",
    video: "https://storage.googleapis.com/chapters-ifm/testfolder/2022/COVERED_CALL/margin_home.mp4",
    description:
      "Maintenance margin, Initial margin requirements for basic to advanced strategies",
    active: 1,
    category: "risk_management",
    level: "P"
  },
  {
    name: "Margin for Call Seller",
    id: "margin_call_seller",
    Component: posts.Margin_Call_Options,
    image:
    "",
    video: "https://storage.googleapis.com/chapters-ifm/testfolder/2022/COVERED_CALL/margin_home.mp4",
    description:
      "Maintenance margin, Initial margin requirements for basic to advanced strategies",
    active: 1,
    category: "risk_management",
    level: "P"
  },
  {
    name: "Margin for Put seller",
    id: "margin_Put_seller",
    Component: posts.Margin_Put_Options,
    image:
    "",
    video: "https://storage.googleapis.com/chapters-ifm/testfolder/2022/COVERED_CALL/margin_home.mp4",
    description:
      "Maintenance margin, Initial margin requirements for basic to advanced strategies",
    active: 1,
    category: "risk_management",
    level: "P"
  },

  {
    name: "Markets",
    id: "markets",
    Component: posts.MarketData,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/2022/markets_pic.png",
    video: "",
    description:
      "Outperforming stocks. High Volume options trades",
    active: 1,
    category: "online",
    level: "P"
  },

  {
    name: "Test Quiz",
    id: "test",
    Component: posts.ExperimentLayout,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/2022/markets_pic.png",
    video: "",
    description:
      "Outperforming stocks. High Volume options trades",
    active: 0,
    category: "online",
    level: "P"
  },
  {
    name: "Test Quiz",
    id: "quiz",
    Component: posts.May20Quiz,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/2022/markets_pic.png",
    video: "",
    description:
      "Outperforming stocks. High Volume options trades",
    active: 0,
    category: "online",
    level: "P"
  },


 
];
function Default() {
  const text = "Hello World . I am Default APP";

  return (
    <div className="App">
      <p> {text} </p>
    </div>
  );
}

function App1() {
  const text = "Hello World . I am inside Pages / App1";

  return (
    <div className="App">
      <p> {text} </p>
    </div>
  );
}
