
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import ReactPlayer from 'react-player'
import Content from "../../components/Content";
import Container from "../../components/Layouts/Container";
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
export const meta = {
  title: " This is my Title2",
  chapter: "Chapter1",
  ispublished: true,
  date: "21 dec 2021",
  author: "Pavan Kumaar Mirla"
};
export const ggbParameters = {
  // material_id: "",
  // width: 1700,
  // height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const opts = {
  height: '390',
  width: '510',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Intro = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4' mdxType="ReactPlayer" />
  </div>;
export const DefaultBox = () => <div style={{
  padding: 20,
  backgroundColor: 'yellow'
}} />;
export const Text4 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const componetArray = [<DefaultBox mdxType="DefaultBox" />, <Box1 mdxType="Box1" />];
export const indexArray = {
  button1: 1,
  text14: 1
};
export const items1 = [{
  title: 'Option premium = Intrinsic value + Extrinsic value',
  subtitle: "The value of options is a combination of intrinsic and extrinsic components. As time passes, the time value portion disappears until at expiration, the option is worth its intrinsic value"
}, {
  title: 'What determines Intrinsic and Extrinsic value?',
  subtitle: 'The intrinsic value is based on the monyness of the option. This is how much is the option in the money. Extrinsic value is determined by options greeks. These show how volatile, how time-sensitive, and how rate-sensitive an option price is.'
}, {
  title: 'What is Theta?',
  subtitle: 'Theta is the rate at which an option loses value as time passes, while all other market conditions remain unchanged'
}, {
  title: 'Why does Theta have a negative sign?',
  subtitle: "As all options lose value over time, they are usually displayed as negative numbers. Theta is the amount by which the option value decreases each day."
}, {
  title: 'Theta decay is not linear.',
  subtitle: "Theta or time decay is not linear. As the time to expiration decreases, the theoretical rate of decay will increase. Accordingly, the amount of decay indicated by Theta tends to be gradual at first, but accelerates as expiration approaches. "
}, {
  title: 'ATM options will have most exposure to time decay',
  subtitle: "Deep in the money(ITM) or far out-of-the-money (OTM) options will have little decay since their time premium is lower. "
}, {
  title: 'Higher implied volatility leads to larger Theta',
  subtitle: "The implied volatility of an option will determine the amount of time premium, which will affect the amount of Theta. Theta tends to rise as implied volatility rises in general"
}];
export const items2 = [{
  title: 'How are Gamma and Theta related?',
  subtitle: "Gamma and theta almost always have the opposite sign.that is they are inversely related. Gamma will be positive when theta will be negative, and vice versa.  Their magnitudes are positively corrlated .When gamma is large, theta will also be large, but of the opposite sign. Smaller gammas will be accompanied by smaller thetas"
}, {
  title: 'How is theta profile for Short Call and Puts?',
  subtitle: "Explanations of these option types can be found in the section dedicated to thetas."
}];

const layoutProps = {
  meta,
ggbParameters,
opts,
Box1,
Intro,
DefaultBox,
Text4,
componetArray,
indexArray,
items1,
items2
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">










    <h1>{`Theta for Long Call position`}</h1>

    <Container mdxType="Container">
  <Content items={items1} title={"Basics"} mdxType="Content" />
    </Container>
  <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/theta_long_call_1.json" ggbParameters={ggbParameters} appId="debit_put_spread" email="pavanmirla@gmail.com" data={[{
      component: <Intro mdxType="Intro" />,
      button: "text11"
    }, {
      component: <Text4 mdxType="Text4" />,
      button: "text3"
    }]} mdxType="GeogebraDraw" />

    <Container mdxType="Container">
  <Content items={items2} title={"Questions to ask"} mdxType="Content" />
    </Container>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;