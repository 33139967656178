import React from 'react';
import VideoSection from "../components/VideoSection";
import SingleCard from "../components/singleCard";

const mathTopicsJSON = require(`../pages_posts`).default();
const blogs_chain = _.chain(mathTopicsJSON)
// Group the elements of Array based on `color` property
.groupBy("category")
// `key` is group's name (color), `value` is the array of objects
.map((value, key) => ({ category: key, blogs: value }))
.value()


export default [
  {
    id: '1',
    type: 'input',
    data: {
      label: (
        <>
          Beginners <strong>Start here</strong>
        </>
      ),
    },
    position: { x: 250, y: 0 },
  },
  {
    id: '2',
    data: {
      label: (
        <div  style={{  display: "flex",
                        alignItems: "center",
                        justifyContent: "center"}} >
          <SingleCard
                  key={blogs_chain[0].blogs[0].id}
                  title={blogs_chain[0].blogs[0].name}
                  image={blogs_chain[0].blogs[0].image}
                  video ={''} 
                  description={blogs_chain[0].blogs[0].description}
                />
        </div>
      ),
    },
    position: { x: 50, y: 150 },
    style: {
        background: '#D6D5E6',
        color: '#333',
        border: '1px solid #222138',
        width: 280,
        height: 320
      },
  },
//   {
//     id: '3',
//     data: {
//       label: (
//         <>
//           This one has a <strong>custom style</strong>
//         </>
//       ),
//     },
//     position: { x: 400, y: 100 },
//     style: {
//       background: '#D6D5E6',
//       color: '#333',
//       border: '1px solid #222138',
//       width: 480,
//     },
//   },
//   {
//     id: '4',
//     position: { x: 250, y: 200 },
//     data: {
//       label: 'Another default node',
//     },
//   },
//   {
//     id: '5',
//     data: {
//       label: 'Node id: 5',
//     },
//     position: { x: 250, y: 325 },
//   },
//   {
//     id: '6',
//     type: 'output',
//     data: {
//       label: (
//         <div>
//           An <strong>output node</strong>
//         </div>
//       ),
//     },
//     position: { x: 100, y: 480 },
//   },
//   {
//     id: '7',
//     type: 'output',
//     data: { label: 'Another output node' },
//     position: { x: 400, y: 450 },
//   },
  { id: 'e1-2', source: '1', target: '2', label: 'must visit',  animated: true, style: { stroke: '#f6ab6c' },},
  { id: 'e1-3', source: '1', target: '3' },
  {
    id: 'e3-4',
    source: '3',
    target: '4',
    animated: true,
    label: 'animated edge',
  },
  {
    id: 'e4-5',
    source: '4',
    target: '5',
    arrowHeadType: 'arrowclosed',
    label: 'edge with arrow head',
  },
  {
    id: 'e5-6',
    source: '5',
    target: '6',
    type: 'smoothstep',
    label: 'smooth step edge',
  },
  {
    id: 'e5-7',
    source: '5',
    target: '7',
    type: 'step',
    style: { stroke: '#f6ab6c' },
    label: 'a step edge',
    animated: true,
    labelStyle: { fill: '#f6ab6c', fontWeight: 700 },
  },
];
