
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import SimpleAccordion from "../../components/SimpleAccordian";
import IfPaidUser from "../../components/IfPaidUser";
import useFetchData from "../../components/UseFetchData";
import ReactPlayer from 'react-player'
import Stack from '@mui/material/Stack';
import Button from "@material-ui/core/Button";
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import ReactPlayerComp from "../../components/ReactPlayerComp";
import SimpleVideoCard from '../../components/SimpleVideoCard';
import GRCard from "../../components/GRCard";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';

export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Box2 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/videos/Theta_video_drive.mp4' mdxType="ReactPlayer" />
  </div>;
export const Text14 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayerComp url='https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4' mdxType="ReactPlayerComp" />
  </div>;
export const ggbParameters = {
  // material_id: "",
  // width: "100%",
  // height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};

const layoutProps = {
  Box1,
Box2,
Text14,
ggbParameters
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">







    <h1>{`Black Scholes framework for Call option pricing`}</h1>
    <Grid container spacing={1} mdxType="Grid">
      <Grid item xs={12} sm={4} md={4} sx={{
        alignItems: 'center'
      }} mdxType="Grid">
        <Box sx={{
          bgcolor: 'primary.main',
          color: 'primary.contrastText',
          p: 2,
          alignItems: 'center'
        }} mdxType="Box">
          <SimpleVideoCard title="Why are simpler options called European options?" description={"Paul Samuelson was likely the most influential economist of the later half of the 20th century. He coined the term European for options that can be exercised only at maturity"} video={"https://www.youtube.com/embed/RbIzwTGN3Yc?start=563&end=700"} mdxType="SimpleVideoCard" />
        </Box>
      </Grid>
      <Grid item xs={12} sm={4} md={4} mdxType="Grid">
        <Box sx={{
          bgcolor: 'secondary.main',
          color: 'secondary.contrastText',
          p: 2
        }} mdxType="Box">
        <SimpleVideoCard title="Myron Scholes on how Black Scholes Formula is inductive instead of deductive?" description={"He took inspiration from Capital Asset pricing model and portfolio hedging"} video={"https://www.youtube.com/embed/zooUe_oDSpo?start=1080&end=1620"} mdxType="SimpleVideoCard" />
        </Box>
      </Grid>
     
    </Grid>
    <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/basics/black_scholes_illustration_with_density.json" ggbParameters={ggbParameters} appId="appId2" email="pavanmirla@gmail.com" data={[{
      component: <Text14 mdxType="Text14" />,
      button: "text14"
    }]} mdxType="GeogebraDraw" /> 

    <h1>{`For discussion`}</h1>
    <h3>{`Market Volatility`}</h3>
    <p>{` The market sets the premium based on transactions`}</p>
    <p>{` Market reporting of IV basically says, if people were applying BSM when constructing bids, the price would be right.
This value must have been plugged in for volatility`}</p>
    <p>{` Except volatility, other variables for the model is known`}</p>
    <p>{` Implied volatility is the level of volatility in an underlying implied by the price of the option`}</p>
    <p>{` Every option has its own supply and demand`}</p>
    <p>{` Each option has its own price, each has its own IV`}</p>
    <p>{` If you have an options market price and a pricing model for the option, you can calculate the level of volatility in the
underlying price With the prevailing market price, i.e the implied volatility `}</p>
    <p><a parentName="p" {...{
        "href": "https://www.reddit.com/r/options/comments/tgmw9y/blackscholesmerton_model_in_textbooks/"
      }}>{`Reference`}</a></p>
    <h3>{`Single number shown for IV`}</h3>
    <p>{` This singl value shown for IV of an underlying is weighted average of the IV's across different options in option chain`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.reddit.com/r/options/comments/tgl76d/difference_between_iv_of_the_underlying_vs_iv_of/"
      }}>{`Reference`}</a></p>
    <h3>{`Interpretation`}</h3>
    <p>{`IV represents the implied annialized 1-standard deviation move as a percentage of the stock price`}</p>
    <h3>{`Assumptions`}</h3>
    <p>{` IV is constant is big deviation from reality in most cases `}</p>
    <p>{` BSM is a simplifying approximation`}</p>
    <h3>{`Equation`}</h3>
    <p>{` BS equation is a stochastic PDE`}</p>
    <p>{` Easier to write closed form solution for European options`}</p>
    <p>{` Numerous approximation methods can price American options`}</p>
    <p>{` Early exercise is relatively rare `}</p>
    <p>{` Cox Ross Rubinstein is the gold standard for option modeling - models early exercise (Black scholes and beyond book)`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;