
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import VerticalSteps from "../../components/VerticalSteps";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import ReactPlayer from 'react-player'
import Stack from '@mui/material/Stack';
import Button from "@material-ui/core/Button";
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Form from "../../components/NetlifyForm";
import ButtonMailto from "../../components/ButtonMailto.js";
import Content from "../../components/Content";
import Container from "../../components/Layouts/Container";
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
import VideoSection from "../../components/VideoSection";
export const items1 = [{
  title: "Why use returns?",
  subtitle: "In order to normalize data, it is useful to calculate returns in percentage terms. Each variable may start at a different price point. Retun is a key characteristic of most statistical techniques"
}, {
  title: 'Why to use log returns?',
  subtitle: "log-normal returns are frequently used in finance due to their statistical properties and computational efficiency. A product of two normally distributed variables is not normal, while a sum of normally distributed variables is normal. Rather than multiplying, logarithms replace multiplication with addition. Remember the basic log normal formula, where the log of the product of two variables equals the sum of the logs of each variable.  "
}];
export const items3 = [{
  title: 'What is sensitivity of Delta to volatility?',
  subtitle: "Uncertainty rises as volatility increases. Stocks can end up in a wide range. Therefore, delta profile spreads wider when volatilities are higher. On the other hand, when volatility is low, it is closer to binary.In step3, observe delta profile. By changing volatility to 1 and lowering to .1, you will observe this. "
}, {
  title: 'What is sensitivity of Delta to time to expiration ?',
  subtitle: ' In-the-money Puts with a longer time until expiration always have a lower Delta than the same strike Put with a shorter time until expiration'
}];
export const ggbParameters = {
  // material_id: "",
  // width: "100%",
  // height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};

const layoutProps = {
  items1,
items3,
ggbParameters
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Normal Log Normal relationship`}</h1>
    <Container mdxType="Container">
  <Content items={items1} title={"Basics"} mdxType="Content" />
    </Container>
    <GeogebraDraw jsonUrl=" https://storage.googleapis.com/chapters-ifm/testfolder/math/normalormal_relationship.json" ggbParameters={ggbParameters} appId="log_id" email="pavanmirla@gmail.com" data={[]} mdxType="GeogebraDraw" /> 


  <Box sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: 'center'
    }} mdxType="Box">
              <ButtonMailto label="Write me an E-Mail" mailto="mailto:office@21ifm.com" mdxType="ButtonMailto" />
  </Box>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;