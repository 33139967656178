import  {useState,useEffect,useReducer,useRef} from "react";
import GetGGBJson from "./GetGGBJson";
import Demo from './DraggableDialog';
const findIndex = require('lodash').findIndex;
const includes = require('lodash').includes;
const map = require('lodash').map;

export default function GeogebraDraw (props) {
    const [clickItem, setClickItem] = useState('')
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const [isOpen, setIsOpen] = useState(false);
    const [index, setIndex] = useState(0);
    let countref = useRef(0)
    let clickRef= useRef('')
    function handleClick(d) {
      countref.current++;
      setClickItem(d)
      forceUpdate() 
      props.whatWasClick(d)
      // setIsOpen(!isOpen)
      //forceUpdate() //required. whhen same id is clicked in succession issue
    }
  const data = props.data
    useEffect(() => {
      clickRef.current = clickItem
      if (typeof clickRef.current !== "undefined" && includes(map(data, 'button'),clickRef.current)   ) {
      setIndex(findIndex(data, (e)=> e.button===clickRef.current))
      setIsOpen(true)
    } else {
        setIsOpen(false)
    }
    },[clickItem]);
  return (
      <>
      {/* <h1 style={{position: 'sticky', top: 200, left: 100, zIndex: 10000}}> This is what I clicked: {clickItem} {countref.current}</h1> */}
      {/* {isOpen && <Demo style={{display:'none'}} open={isOpen} heading={clickItem} email={props.email} component={data[index].component}/>} */}
      <GetGGBJson
            jsonUrl= {props.jsonUrl}
            ggbParameters={props.ggbParameters}
            appId={props.appId}
            stateChanger={setClickItem}
            onClickHandle = {(d)=> {
              handleClick(d);
            }}
    /> 
    </>
    )
   }
