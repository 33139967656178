import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";


import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import Heading from "./components/Heading";

import SignUp from "./components/SignUp";
import VideoSection from "./components/VideoSection";

import SignInMagicLink from "./components/SignInMagicLink";
import MagicLinkWait from "./components/MagicLinkWait";
import SignIn from "./components/SignIn";

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import ForgotPassword from "./components/ForgotPassword";
import GeogebraDraw from "./components/GeogebraDraw";

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import Demo from './components/SimpleVideoCard';
import OverviewFlow from './components/OverviewFlow';
import GridList from './components/GridList';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import MessageModal from  "./components/MessageModal";
import ButtonMailto from "./components/ButtonMailto.js";
import WhoAmI from "./components/WhoAmI.js";
import Sample from "./components/Pdf/Sample.jsx";
// import InLine from "./components/InLine"; 
// const About = lazy(() => importMDX('./pages/About.mdx'));
import { Portal } from "react-portal";
import YouTube from "react-youtube";


import CardsForBlogs from "./CardsForBlogs"
import AppHeader from "./components/AppHeader";
import SignUpLicense from "./components/SignUpLicense";
import AreaMap from "./components/AreaMap"

const darkTheme = createMuiTheme({
  palette: {
    type: "dark"
  },
  typography: {
    subtitle1: {
      fontSize: 12
    },
    body1: {
      fontWeight: 500
    }
  }
});

const componentsMap = {
  h1: Heading.h3, // mapping h3 to h1 so we're having a smaller font-size
  h2: Heading.h4
};

function SubHeading(props) {

  return (
    <>
    <Grid container >
    <Grid item xs={12} sx={{
        // mt: 250,
    bgcolor: '#1E1E1E',
    width: 100,
    p: 1,
    // m: 1,
    borderRadius: 1,
    textAlign: 'center',
  }}>
    <Typography variant="h3" gutterBottom component="div"  color="#90caf9">
       {props.title}
    </Typography>
    </Grid>
  </Grid>

  </>
  )
}

let blogs_chain
export default function MathApp() {

  const [isDrawerOpen, SetIsDrawerOpen] = useState(false);
  const [userProfile, SetuserProfile] =  useState("notbeginner")

  let mathTopicsJSON =[]

  try {

    mathTopicsJSON = require(`./pages_posts`).default();
    blogs_chain = _.chain(mathTopicsJSON)
    // Group the elements of Array based on `color` property
    .groupBy("category")
    // `key` is group's name (color), `value` is the array of objects
    .map((value, key) => ({ category: key, blogs: value }))
    .value()
    console.log(blogs_chain)

  } catch (err) {}

  const handleDrawerClick = () => {
    // SetIsDrawerOpen(false);
    SetIsDrawerOpen(prevCheck => !prevCheck);
  };




  const handleIsbeginnerClick =(profile) => {
    SetuserProfile(profile)
  }

  const clicked_on = (shape) => 
{
	alert ( "You clicked on the " + shape );
}

 const  ggbParameters = {
    // material_id: "",
    // width: "100%",
    // height: "100%",
    borderColor: "#1E1e1E",
    enableShiftDragZoom: false,
    showToolBar: false
  };


  
  const MyDynamicButton = () => {
    const [count, setCount] = useState(0)
    const [show, setShow] = useState(false)
    return (
      <Box textAlign='center'>
       <Button
                color={'primary'}
                variant={'contained'}
                size={'large'}
                style={{margin: '0 auto', display: "flex"}}
                onClick={() => { 
                if(show==false) setShow(true)
                if(show==true) setShow(false)
                setCount(count + 1)}
        }
              >
                Click to  {show==true? 'Close 3D App' : ' view Payoff surface in 3D ' }
              </Button>
      { show==true && 
         <GeogebraDraw 
              jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/options_intro/journey_treasure.json"
              ggbParameters={ggbParameters}
              appId="appId2"
              email="pavanmirla@gmail.com"
              data={[
         
              ]}
            /> 
      }
      </Box>
    )
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <Router>
        <Switch>
    
         {
            mathTopicsJSON.map(({id, Component}) => (
              <Route key={id} path={"/"+id} > 
                {Component}
              </Route>
            ))
          }  
        <Route exact path="/">
        <AppHeader handleDrawerToggle={handleDrawerClick}/>
        <div style={{marginTop: "100px"}}></div>
          {/* <SubHeading title={"Welcome to Interactive Learning: A Modern Approach"}/> */}
            {blogs_chain.length > 0 &&
          <>
      
  



       <VideoSection video={"https://storage.googleapis.com/chapters-ifm/testfolder/2022/video_for_linkedin.mp4"}/>
      
       <div style={{padding:20, color: 'gray', display: "flex", margin: "auto", width: "50%"}}>
      </div>


      <Box sx={{ 
              display: "flex" ,
              alignItems: "center",
              justifyContent: 'center'
            }}>
              <ButtonMailto label="Email" mailto="mailto:office@21ifm.com?subject=Send me sample chapter!&body=Thanks!" />
      </Box>

      <br/>
      <br/>
      <br/>



{/* 
    <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="20vh"
    >
          <Typography variant={'h4'} fontWeight={700} gutterBottom color={"#90caf9"}>
                        INTERCATIVE FINANCIAL MATHEMATICS  FOR FINANCE PROFESSIONALS AND NEW COMERS
                        </Typography>
  </Box> */}


    {/* <Grid container spacing={10}
              justify="center" 
              direction="row"
              alignItems="center"
              justifyContent="center"
      >
      <Grid item xs={12} md={6} justifyContent="center">
          <WhoAmI  title="Options are new to me" 
                            description= "My background isn't in finance or math. With guidance, I'd like to start at the very beginning. I would like to learn about advanced strategies step by step, gradually" 
                            profile="beginner" 
                            image="https://storage.googleapis.com/chapters-ifm/testfolder/basics/galton_bonomial.gif"
                            handleClick = {handleIsbeginnerClick}
                    />
      </Grid>
      <Grid item xs={12} md={6} justifyContent="center">
          <WhoAmI  title="I'm familiar with options" description= "I need to brush up on my understanding of options pricing, advanced strategies, and the interrelationships between Greeks at the strategy level." profile="notbeginner"
                                image="https://storage.googleapis.com/chapters-ifm/testfolder/basics/galton_bonomial.gif"
                                handleClick = {handleIsbeginnerClick}
                        />
      </Grid>
      
      </Grid> */}

<br/>


            {/* <MyDynamicButton /> */}


           {/* <Box sx={{ 
                  display: "flex" ,
                  alignItems: "center",
                  justifyContent: 'center',
                  width: "100%",
                  backgroundColor: "#1e1e1e"
                  // minHeight: "2000px"
                }}>
           <iframe src="http://docs.google.com/gview?url=https://storage.googleapis.com/chapters-ifm/testfolder/2022/learning_plan.pdf&embedded=true" width="100%" height="1000px"  style={{width:"718px", height:"1700px;"}} frameborder="0"></iframe>
           </Box> */}

          {/* { userProfile == "beginner" && <> 
          <br/>
          <br/>
          <br/>
          <VideoSection video={"https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/journey_beginner_fixed.mp4"}/>
          <br/>
          </>
          }

          {
          userProfile=="notbeginner" && <>
          <br/>
            <VideoSection video={"https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/intro_advanced.mp4"}/>
          <br/>
            </>
          } */}

          <CardsForBlogs in_json={blogs_chain[0].blogs} title="Featured interactive options explainers for finance professionals" 
           description = "Long descption"
           image = "none"/>

           <CardsForBlogs in_json={blogs_chain[1].blogs} title="Popular interactives for newcomers to options" description = "Long descption"
           image = "none" />

           <CardsForBlogs in_json={blogs_chain[2].blogs} title="CALL OPTION PRICING AND CALL OPTION GREEKS" 
           description = "Long descption"
           image = "none"/>

           <CardsForBlogs in_json={blogs_chain[3].blogs} title="ESSENTIAL MATH AND STATS" description = "Long descption"
           image = "https://storage.googleapis.com/chapters-ifm/testfolder/basics/galton_bonomial.gif" />



          <CardsForBlogs in_json={blogs_chain[4].blogs} title="PRICING MODELS. BLACK SCHOLES MODEL. BINOMIAL MODEL " description = "Long descption"
          image = "https://storage.googleapis.com/chapters-ifm/testfolder/2022/pricing_section_3.gif" />


        
         <CardsForBlogs in_json={blogs_chain[5].blogs} title="PUT OPTION PRICING AND PUT OPTION GREEKS" description = "Long descption"
           image = "none" />

         <CardsForBlogs in_json={blogs_chain[6].blogs} title="SYNTHETICS. PUT-CALL PARITY. ARBITRAGE" description = "Long descption"
           image = "none" />


    
         <CardsForBlogs in_json={blogs_chain[7].blogs} title="IMPLIED VOLATILITY. VOLATILITY FORECASTS" description = "Long descption" 
          image = "none"/>


          <CardsForBlogs in_json={blogs_chain[8].blogs} title="BEARISH ON STOCK.VOL IS LOW " description = "Long descption" 
          image = "none"/>
  

        
         { userProfile == "notbeginner" && <> 
         
         <CardsForBlogs in_json={blogs_chain[9].blogs} title="BEARISH ON STOCK.VOL IS HIGH" description = "Long descption" 
          image = "none"/>

          <CardsForBlogs in_json={blogs_chain[10].blogs} title="BULLISH ON STOCK.VOL IS LOW" description = "Long descption"
          image = "none" />

          <CardsForBlogs in_json={blogs_chain[11].blogs} title="BULLISH ON STOCK.VOL IS HIGH" description = "Long descption"
                    image = "none" />

          <CardsForBlogs in_json={blogs_chain[12].blogs} title="VOLATILITY STRATEGIES " description = "Long descption"
                    image = "https://storage.googleapis.com/chapters-ifm/testfolder/2022/vol_section_2.gif" />

          <CardsForBlogs in_json={blogs_chain[13].blogs} title="STRATEGIES WITH DIFFERENT MATURITIES. HORIZONTAL SPREAD" description = "Long descption"
                    image = "none" />      

          {/* <CardsForBlogs in_json={blogs_chain[14].blogs} title="INCOME GENERATION WITH OPTIONS WHILE HOLDING UNDERLYING" description = "Long descption"
                    image = "none" /> */}


         </>

         }
          
{/* 
          <CardsForBlogs in_json={blogs_chain[15].blogs} title="RISK MANAGEMENT" description = "Long descption"
          image = "none" />
          <CardsForBlogs in_json={blogs_chain[16].blogs} title="WEEKLY MARKET NEWS, OPPORTUNITIES AND CONCEPT REVISIONS" description = "Long descption"
          image = "none" /> */}




<Box sx={{ 
              display: "flex" ,
              alignItems: "center",
              justifyContent: 'center'
            }}>
              <ButtonMailto label="sample chapter" mailto="mailto:office@21ifm.com?subject=Send me sample chapter!&body=Thanks!" />
</Box>





{/* <div style={{ width: '100%' }}>
    <Grid container spacing={1}>
      <Grid item xs={12} sm={4} sx= {{alignItems: 'center'}}>
        <Box sx={{ bgcolor: 'primary.main', color: 'primary.contrastText', p: 2, alignItems: 'center' }}>
          <Demo title ="When to sell options?" description={"Options expert Larry McMillan explaining selling options is profitable only when current market volatility is high"} video = {"https://www.youtube.com/embed/88eG8-3qpds"}/>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box
          sx={{
            bgcolor: 'secondary.main',
            color: 'secondary.contrastText',
            p: 2,
          }}
        >
          <Demo title ="Lizard" description={"This is my description"} video = "https://storage.googleapis.com/chapters-ifm/testfolder/2022/optionsIntroAd.mp4"/>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box sx={{ bgcolor: 'error.main', color: 'error.contrastText', p: 2 }}>
         <Demo title ="Lizard" description={"This is my description"} video = "https://storage.googleapis.com/chapters-ifm/testfolder/2022/optionsIntroAd.mp4"/>
        </Box>
      </Grid>
     
    </Grid>
  </div> */}

          {/* <SubHeading title={"Synthetics"}/>

          <SubHeading title={"Advanced Strategies"}/>

          <SubHeading title={"Risk Management"}/>

          <SubHeading title={"Black Scholes Options pricing"}/> */}



          {/* <CardsForBlogs in_json={blogs_chain[3].blogs} /> */}
          </>
         }
        </Route>
   

        <Route path="/taxes">
          <h2>Taxes</h2>
        </Route>


        <Route path="/loans">
          <h2>Loans</h2>
        </Route>

{/* 
        <Route path="/signup">
          <SignUp />
        </Route> */}
        <Route path="/register">
          <SignUpLicense />
        </Route>

        <Route path="/signinLink">
          <SignInMagicLink />
        </Route>


        <Route path="/wait">
          <MagicLinkWait />
        </Route>

        <Route path="/forgotPassword">
          <ForgotPassword />
        </Route>

        <Route path="/signin">
          <SignIn />
        </Route>
 

        <Route path="/__/auth/">
          <SignIn />
        </Route>

        </Switch>
      </Router>
    </ThemeProvider>
  );
}

function Home() {


  return (
    <div >
      {/* <AppHeader handleDrawerToggle={handleDrawerClick}/> */}
      <main >
     
      </main>
    </div>
  );
}


