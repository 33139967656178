
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import Content from "../../components/Content";
import Container from "../../components/Layouts/Container";
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
export const meta = {
  title: " This is my Title2",
  chapter: "Chapter1",
  ispublished: true,
  date: "21 dec 2021",
  author: "Pavan Kumaar Mirla"
};
export const ggbParameters = {
  // material_id: "",
  // width: 1800,
  // height: 950,
  borderColor: "#1e1e1e",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const opts = {
  height: '390',
  width: '510',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Box2 = () => <div style={{
  padding: 20,
  backgroundColor: 'green'
}} />;
export const DefaultBox = () => <div style={{
  padding: 20,
  backgroundColor: 'yellow'
}} />;
export const Text4 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const componetArray = [<DefaultBox mdxType="DefaultBox" />, <Box1 mdxType="Box1" />, <Box2 mdxType="Box2" />];
export const indexArray = {
  button1: 1,
  text14: 1
};
export const Box11 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="FAwDrUqpGUI" opts={opts} mdxType="YouTube" />
  </div>;
export const Box22 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const items1 = [{
  title: 'Put-call parity: what is it?',
  subtitle: 'The value of European calls with particular exercise prices and dates can be determined from European puts with the same exercise price and date, and vice versa. '
}, {
  title: 'Why should this relationship hold true?',
  subtitle: 'If this relationship does not hold true, there will be arbitrage opportunities that will lead to inefficient markets. A market that is efficient will prevent arbitrage.'
}];
export const items2 = [{
  title: 'Payoff of each investment as stock price changes',
  subtitle: 'You can see that the combined payoff of portfolios A and B (in blue) has the same profile. To see the payoff of each investment, use the stock price slider (yellow traingle)'
}, {
  title: 'Why is the stock payoff starts from zero?',
  subtitle: 'We are plotting the payoff of the PNL. This stock PnL is after the stock was purchased at the current stock ATM. Stock = strike at purchase time.'
}, {
  title: 'Where is Volatility parameter?',
  subtitle: 'To avoid complexity, volatility is not shown. The payoff shown here is the payoff at expiration.  '
}, {
  title: 'Is there an arbitrage example?',
  subtitle: 'Yes.   '
}];

const layoutProps = {
  meta,
ggbParameters,
opts,
Box1,
Box2,
DefaultBox,
Text4,
componetArray,
indexArray,
Box11,
Box22,
items1,
items2
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">













    <h1>{`Introduction`}</h1>

    <Container mdxType="Container">
  <Content items={items1} title={"Basics"} mdxType="Content" />
    </Container>
    <br />
    <h1>{`The Put-call relationship`}</h1>
 <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/put_call_parity_2022.json" ggbParameters={ggbParameters} appId="id2" email="pavanmirla@gmail.com" data={[{
      component: <Box1 mdxType="Box1" />,
      button: "button1"
    }, {
      component: <Box11 mdxType="Box11" />,
      button: "textn1"
    }, {
      component: <Box22 mdxType="Box22" />,
      button: "textn2"
    }]} mdxType="GeogebraDraw" />

    <Container mdxType="Container">
  <Content items={items2} title={"Explainer Highlights"} mdxType="Content" />
    </Container>
    <br />
    <h1>{`references`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.optionseducation.org/advancedconcepts/put-call-parity"
        }}>{`https://www.optionseducation.org/advancedconcepts/put-call-parity`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://blog.quantinsti.com/basics-options-trading/"
        }}>{`https://blog.quantinsti.com/basics-options-trading/`}</a></li>
      <li parentName="ul">{`Keith Cuthbertson - Derivatives Theory and Practice`}</li>
    </ul>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;