
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import { useState } from 'react'
import Alert from '@mui/material/Alert';
import data from '../../data/tulip_spot_trade.json';
import GeogebraComponent from "../../components/GeogebraComponent";
import VideoSection from "../../components/VideoSection";
export const opts = {
  height: "390",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    start: 5,
    end: 24
  }
};
export const short_position = data.data;
export const short_position_params = {
  // material_id: "",
  width: 1800,
  height: 900,
  borderColor: "#1E1E1E",
  enableShiftDragZoom: false,
  ggbBase64: short_position
};
export const meta = {
  title: " This is my Title2",
  chapter: "Chapter1",
  ispublished: true,
  date: "21 dec 2021",
  author: "Pavan Kumaar Mirla"
};
export const Highlight = ({
  children,
  color
}) => <span style={{
  backgroundColor: color,
  borderRadius: '2px',
  color: '#fff',
  padding: '0.2rem'
}}>
    {children}
  </span>;
export const ggbParameters = {
  // material_id: "",
  width: "100%",
  height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Box2 = () => <div style={{
  padding: 20,
  backgroundColor: 'green'
}} />;
export const DefaultBox = () => <div style={{
  padding: 20,
  backgroundColor: 'yellow'
}} />;
export const Text4 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const componetArray = [<DefaultBox mdxType="DefaultBox" />, <Box1 mdxType="Box1" />, <Box2 mdxType="Box2" />];
export const indexArray = {
  button1: 1,
  text14: 1
};
export const MyDynamicButton = () => {
  const [open, setOpen] = useState(false);
  return <>
    <button onClick={() => setOpen(true)}>Click to open Bid Ask App</button>
    <button onClick={() => setOpen(false)}>Click to close Bid Ask</button>
    {open && <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/math/bid_ask_tulip_matthias1.json" ggbParameters={ggbParameters} appId="DefinfitionVector2" email="pavanmirla@gmail.com" data={[{
      component: <Box1 mdxType="Box1" />,
      button: "button1"
    }, {
      component: <Box2 mdxType="Box2" />,
      button: "text14"
    }, {
      component: <Text4 mdxType="Text4" />,
      button: "text4"
    }]} mdxType="GeogebraDraw" />}
  </>;
};
export const Figure = props => {
  return <figure class="chart">
      {
      /* <object data={props.data} type="image/svg+xml"></object> */
    }
      <img src={props.data} alt="yoast seo" height={props.height} width={props.width} />
      <figcaption>{props.caption}</figcaption>
    </figure>;
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Intro = makeShortcode("Intro");
const layoutProps = {
  opts,
short_position_params,
meta,
Highlight,
ggbParameters,
Box1,
Box2,
DefaultBox,
Text4,
componetArray,
indexArray,
MyDynamicButton,
Figure
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">













    <h1>{`What are Options?`}</h1>
    <p>{`Options are a type of derivative. `}</p>
    <VideoSection video={"https://storage.googleapis.com/chapters-ifm/testfolder/2022/what_are_options_cow2.mp4"} mdxType="VideoSection" />
    <p>{`A derivative is a financial instrument whose value is derived from the value of another asset: the underlying. `}</p>
    <h1>{`what are call options?`}</h1>
    <p>{`An Call option gives the option owner the right, but not the obligation, to buy or
sell the underlying asset at a specified price any time during a designated
period or on a specified date. `}</p>
    <p>{`With call options, traders can :`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Speculate of the direction of stock `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Can earn credit by selling call, on held underlying`}</p>
      </li>
    </ul>
    <h1>{`Call Option and pizza coupon`}</h1>
    <VideoSection video={"https://storage.googleapis.com/chapters-ifm/testfolder/2022/options_intro/pzza_video_payoff_goal_oriented.mp4"} mdxType="VideoSection" />
    <h1>{`call intrinsic value and profit graphs`}</h1>
  <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/calls_parity_long_short_v1.json" ggbParameters={ggbParameters} appId="parity_grap_call" email="pavanmirla@gmail.com" data={[{
      component: <Intro mdxType="Intro" />,
      button: "text11"
    }, {
      component: <Text4 mdxType="Text4" />,
      button: "text3"
    }]} mdxType="GeogebraDraw" />
    <h1>{`what are put options?`}</h1>
    <p>{`A Put option gives the option owner the right, but not the obligation, to sell`}</p>
    <p>{`the underlying asset at a specified price within a set time interval. Holding a Put option is like buying `}</p>
    <p>{`an car insurance policy`}</p>
    <h1>{`Put intrinsic value and profit graphs`}</h1>
  <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/puts_parity_graph.json" ggbParameters={ggbParameters} appId="parity_graph_put" email="pavanmirla@gmail.com" data={[{
      component: <Intro mdxType="Intro" />,
      button: "text11"
    }, {
      component: <Text4 mdxType="Text4" />,
      button: "text3"
    }]} mdxType="GeogebraDraw" />
    <h1>{`AT A GLANCE. KEY OPTIONS CONCEPTS`}</h1>
    <p>{`Beginners should familiarize themselves with key concepts such as why options were invented, why they are traded, etc. `}</p>
    <p>{`Using a story, let's introduce our characters who might have a need to trade options `}</p>


    <h1>{`Spot transaction`}</h1>
    <p>{`Let's say Farmer Smith 👨‍🌾 wishes to sell unique Tulips 🌷from his farm for $10 each.`}</p>
    <p>{`In exchange for the ownership of the Tulips, Jerry (the trader) can agree to pay the Tulip price.`}</p>
    <p>{` Jerry and Farmer Smith entered into a spot or cash 💵 transaction in this case.`}</p>
    <p align="center">
      <Figure data="https://ichef.bbci.co.uk/news/976/cpsprodpb/E794/production/_101248295_tulips_painting_alamy.jpg" caption="A Tulip trade illustration. Source: Ref#1" height="500" width="588" mdxType="Figure" />
    </p>
    <h1>{`Forward Contract`}</h1>
    <p>{`Due to Trader Jerry's wedding within a year ⏳, he thought it would be best to approach Farmer Smith differently. `}</p>
    <p>{`Trader Jerry will pay Farmer Smith $10 for each Tulip, but he will ask Farmer Smith to complete the transaction later. Not now.`}</p>
    <p>{`At later time, Farmer Smith will receive payment, and Jerry will take possession of the Tulips.`}</p>
    <p>{`Jerry and Farmer Smith will have entered into a forward contract 🤝🏽if both parties agree. `}</p>
    <h1>{`Expiration date`}</h1>
    <p>{`A forward contract allows the parties to agree on the terms now, but the actual transfer of money or goods doesn't occur until a later date, the maturity or `}<strong parentName="p">{`expiration date`}</strong>{` .`}</p>
    <p>{`In a forward contract, the parties agree on the terms now, but nothing is actually exchanged until a later date, the maturity or expiration date.`}</p>
    <h1>{`Forward price`}</h1>
    <p>{`The price Farmer Smith will ask for his Tulips in a year is unlikely to be the same price he is asking today.`}</p>
    <p>{`There may be advantages or disadvantages for one party or another since both the payment and the transfer of goods are deferred. `}</p>
    <p>{`Farmer Smith might point out that if he receives full payment of right now, he can deposit the money in his bank account 🏦 and begin earning interest. `}</p>
    <p>{`However, he will have to forego any interest earnings under a forward contract. `}</p>
    <p>{`In this circumstance, Farmer Smith could insist that he and Jerry negotiate 🤝a one-year price that takes into account this loss of interest.`}</p>
    <h1>{`Futures Contract`}</h1>
    <p>{`Forward contracts traded on organized exchanges 🏛️ are often referred to as futures contracts.`}</p>
    <p>{`A futures exchange standardizes the contract 🙏specifications for forward contracts to make trading easier.`}</p>
    <p>{`The exchange specifies the quantity and quality of the goods to be delivered, the date and place of delivery, and the payment method. `}</p>
    <p>{`Furthermore, the exchange guarantees the integrity of the contract 🔏👏. `}</p>
    <p>{`In the event of default by either the buyer or the seller, the exchange is responsible for fulfilling the terms of the forward contract 👍.`}</p>
    <h1>{`Option Contract`}</h1>
    <p>{`Jerry seems to have a new problem. `}</p>
    <p>{`What if his fiancee walks out on their wedding plans? `}</p>
    <p>{`If the wedding does not happen, Jerry wants to be free of any obligation to buy Tulips.`}</p>
    <p>{`Jerry approaches Farmer Smith with a new proposal.`}</p>
    <p>{`Farmer Smith and Jerry will negotiate a one-year forward price for Tulips, but Jerry will have one year to decide whether to purchase Tulips.`}</p>
    <p>{`Within one year, Jerry can either buy Tulips at the agreed-on forward price, or walk away with no obligation or penalty`}</p>
    <h1>{`Call Option`}</h1>
    <p>{`Jerry realizes Farmer Smith's dilemma and offers to negotiate a separate payment as compensation for this uncertainty.`}</p>
    <p>{`In effect, Jerry is offering to buy the right to decide later whether he wants to acquire Tulips.`}</p>
    <p>{`Farmers Smith will keep this separate payment regardless of Jerry's final decision. `}</p>
    <p>{`When Jerry and Farmer Smith agree on both this separate payment and the forward price, they will enter into an option contract.`}</p>
    <p>{`An option contract gives one party the right to make a decision at a later time. `}</p>
    <h1>{`Call Buyer and Seller`}</h1>
    <p>{`Jerry is the buyer of a call option, which entitles him to buy at a later date. `}</p>
    <p>{`Farmer Smith is the call option seller.`}</p>
    <h1>{`Put Option as Insurance`}</h1>
    <p>{`Jerry is considering options in case Tulip prices drop and his fiance no longer desires Tulips.`}</p>
    <p>{`Jerry, the trader, has purchased insurance for Tulips.`}</p>
    <p>{`Tulips were insured so he could sell them back at a fixed price to the insurance company in the future.`}</p>
    <p>{`If Tulips  appreciates in value, Jerry iis under no obligation to sell them to the insurance company.`}</p>
    <p>{`In essence, Jerry has bought a Put option from an insurance company.`}</p>
    <p>{`Purchases of options involve the payment of a premium, just like insurance contracts. `}</p>
    <p>{`A premium is negotiated between the buyer and the seller, and the seller keeps it regardless of the buyer's decision.`}</p>
    <p>{`A contract of insurance may also define an exercise price, the amount the holder (Jerry) will receive if certain events occur `}</p>
    <div>
Ref #1: https://www.bbc.com/news/business-44067178
    </div>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;