import React from "react";
import  {useState,useEffect,useReducer,useRef} from "react";
import YouTube from "react-youtube";
import "../markdown-styles/styles.css";
import ReactPlayer from 'react-player'
import Button from "@material-ui/core/Button";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import GeogebraDraw2 from "./GeogebraDraw2";
import TouchAppIcon from '@mui/icons-material/TouchApp';

 const StyledBox = styled('div')(({ theme, width }) => ({
    color: 'darkslategray',
    backgroundColor: '#FFCE00',
    padding: 8,
    borderRadius: 4,
    margin: "auto",
    textAlign: "center",
    width : width,
    fontFamily: 'Monospace'
  }));

 const  BasicButtons = (props) => {
    const data = props.data
    return (
        <ScrollMenu>
          {data.map((info) => (
            <ActionAreaCard key={info.id} title={info.title} script ={info.script} text={info.text} geogebraRef={props.geogebraRef} />
                  ))}
         </ScrollMenu>
      );
    }

const  ActionAreaCard = (props) =>{
    const [isOpen, setIsOpen] = React.useState(false);
    const [ignored, forceUpdate] = React.useReducer(x => x + 1, 0);
    const handleClicks = (value) => {
        ggbApplet.evalCommand(value)
        if (props.geogebraRef) {
         props.geogebraRef.current.scrollIntoView( { behavior: 'smooth', block: 'start' } )
        }
        setIsOpen(true)
        forceUpdate()
    };
    const darkTheme = createTheme({ palette: { mode: 'dark' } });
    return (
      <>
      <ThemeProvider theme={darkTheme}>
      <Card sx={{ minWidth: 345, m:2, maxHeight: 250,minHeight: 220 }} onClick={()=> handleClicks(props.script)}  style={{backgroundColor: "#1e1e1e", minHeight: '6vw'}}>
        <CardActionArea>
          <CardContent>
             <TouchAppIcon/>
            <Typography variant="body1" display="inline" component="div">
              {props.title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {props.text}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </ThemeProvider>
    </>
    );
  }


export default function LoadGGBLayoutHtml (props) {
    const  ggbParameters = {
        // material_id: "",
        borderColor: "#1E1e1E",
        enableShiftDragZoom: false,
        showToolBar: false
      }
      const [open, setOpen] = React.useState(false)
      const [clickItem, setClickItem] = React.useState('')
       const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
      const geogebraRef = React.useRef();
      function whatWasClick(d) {
          forceUpdate()
          setClickItem(d)
              }
      useEffect(() => {
        forceUpdate()

        },[clickItem]);
  return (
      <>
       <div>
       <div onClick={() => {
              setOpen(true) 
              }}>
         <StyledBox width="30%">{props.buttonTitle}</StyledBox>
      </div>
    {open && <>
                <BasicButtons data={props.data} geogebraRef={geogebraRef}/>
                <div id="divR" ref={geogebraRef}> </div>
            
                    <GeogebraDraw2 
                    jsonUrl={props.geogebraJSON}
                    ggbParameters={ggbParameters}
                    appId="id2"
                    email="pavanmirla@gmail.com"
                    data={[
                  
                    ]}
                    whatWasClick={whatWasClick}
                  />
             
  </>}
  </div>
    </>
    )
   }
