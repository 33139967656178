
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import VerticalSteps from "../../components/VerticalSteps";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import ReactPlayer from 'react-player'
import Stack from '@mui/material/Stack';
import Button from "@material-ui/core/Button";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Form from "../../components/NetlifyForm";
import Content from "../../components/Content";
import Container from "../../components/Layouts/Container";
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
import ButtonMailto from "../../components/ButtonMailto.js";
export const items1 = [{
  title: 'What is risk of selling call options?',
  subtitle: "There is unlmited risk if the stock rises above the sold call strike. Seller is obliged to sell at lower strike. If the call seller does not hold an asset to cover, he owns a short position on the asset. he has to purchase stock at a higher price than strike"
}, {
  title: 'What is Initial Margin requirement?',
  subtitle: 'An Initial Margin Requirement refers to the percentage of equity required when an investor opens a position. Brokers have a methodology to calculate initial margin based on the options procees,market value of Asset and the moneyness of options. Brokers take a larger value from 3 alternate calculations for initial margin. Example is shown in the app below  '
}, {
  title: 'What is Maintenance Margin?',
  subtitle: " The minimum cash balance that an investor must maintain in a margin account after a purchase has been made is maintenance margin.Maintenace margin calculations are similar to initial margin calculations, but current market values for options and underlying asset are used.  In the event that cash balance falls below this treshold, the Broker will ask you to add more funds to raise cash balance above the maintenance requirement"
}, {
  title: 'What is Margin call? ',
  subtitle: " This is a demand from broker to add money to increase cash balance above maintenace requirement or close out your position"
}, {
  title: 'What are advantages of Margin Trading?',
  subtitle: 'A trader can leverage the value of securities she already owns to increase the size of her investment through margin. Stocks can be shorted in Margin account.Margin account is convenient line of credit '
}];
export const items2 = [{
  title: 'When selling naked options, margin requirements will change with stock movement',
  subtitle: "ITM posiiton will require have a higher margin requirment. Likewise, more volatile stocks have larger maintenance margin. Try changing the variables in the left panel of the app above"
}];
export const ggbParameters = {
  // material_id: "",
  // width: "100%",
  // height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Box = makeShortcode("Box");
const Link = makeShortcode("Link");
const layoutProps = {
  items1,
items2,
ggbParameters
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Margin requirements for selling uncovered Call`}</h1>

    <Container mdxType="Container">
  <Content items={items1} title={"Margin for Sold Call"} mdxType="Content" />
    </Container>
    <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/basics/options_margin_template_short_call.json" ggbParameters={ggbParameters} appId="appId1margincall" email="pavanmirla@gmail.com" data={[]} mdxType="GeogebraDraw" /> 

    <Container mdxType="Container">
  <Content items={items2} title={"Margin requirement for sold Calls"} mdxType="Content" />
    </Container>

 <Box sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: 'center'
    }} mdxType="Box">
              <ButtonMailto label="Email Me" mailto="mailto:office@21ifm.com?subject=I saw your demo app. Want to connect!&body=Thanks!" mdxType="ButtonMailto" />
  </Box>
  <Container mdxType="Container">
   <Typography variant="subtitle1" gutterBottom component="div" mdxType="Typography">
        References
      </Typography>
    <Typography variant="caption" display="block" gutterBottom mdxType="Typography">
        <br />
    <Link href="https://cdn.cboe.com/resources/options/margin_manual_april2000.pdf" mdxType="Link">
     {'CBOE Margin Manual'}
     <br />
     {'https://cdn.cboe.com/resources/options/margin_manual_april2000.pdf'}
    </Link>
    <br />
    </Typography>
    </Container>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;