
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import VerticalSteps from "../../components/VerticalSteps";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import ReactPlayer from 'react-player'
import Stack from '@mui/material/Stack';
import Button from "@material-ui/core/Button";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Form from "../../components/NetlifyForm";
import Content from "../../components/Content";
import Container from "../../components/Layouts/Container";
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
export const items1 = [{
  title: 'What is risk of selling Put options?',
  subtitle: "There is unlmited risk if the stock drops above the sold Put strike. Seller is obliged to buy at higher strike. If the Put seller does not hold cash to cover, he has to raise funds to buy the asset. "
}, {
  title: 'What is Initial Margin requirement for Put seller?',
  subtitle: ' Initial Margin Requirement is the minimum cash balance required when an investor opens a short Put position. Brokers have a methodology to calculate initial margin based on the options procees,market value of Asset and the moneyness of options. Brokers take a larger value from 3 alternate calculations for initial margin. Example is shown in the app below  '
}, {
  title: 'What is Maintenance Margin? for Put seller',
  subtitle: " The minimum cash balance that an investor must maintain in a margin account after a purchase has been made is maintenance margin.Maintenace margin calculations are similar to initial margin calculations, but current market values for options and underlying asset are used.  In the event that cash balance falls below this treshold, the Broker will ask you to add more funds to raise cash balance above the maintenance requirement"
}, {
  title: 'What is Margin call? ',
  subtitle: " This is a demand from broker to add money to increase cash balance above maintenace requirement or close out your position"
}, {
  title: 'What are advantages of Margin Trading?',
  subtitle: 'A trader can leverage the value of securities she already owns to increase the size of her investment through margin. Stocks can be shorted in Margin account.Margin account is convenient line of credit '
}];
export const items2 = [{
  title: 'When selling naked options, margin requirements will change with stock movement',
  subtitle: "ITM posiiton will require have a higher margin requirment. Likewise, more volatile stocks have larger maintenance margin. Try changing the variables in the left panel of the app above"
}];
export const ggbParameters = {
  // material_id: "",
  // width: "100%",
  // height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};

const layoutProps = {
  items1,
items2,
ggbParameters
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Margin requirements for selling uncovered Put`}</h1>

    <Container mdxType="Container">
  <Content items={items1} title={"Margin for Sold Put"} mdxType="Content" />
    </Container>
    <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/options_margin_template_short_put.json" ggbParameters={ggbParameters} appId="appId1margincall" email="pavanmirla@gmail.com" data={[]} mdxType="GeogebraDraw" /> 

    <Container mdxType="Container">
  <Content items={items2} title={"Margin requirement for sold puts"} mdxType="Content" />
    </Container>

    <p><a parentName="p" {...{
        "href": "https://youtu.be/oyu8u2NncHE?t=656"
      }}>{`https://youtu.be/oyu8u2NncHE?t=656`}</a>{`
`}<a parentName="p" {...{
        "href": "https://cdn.cboe.com/resources/options/margin_manual_april2000.pdf"
      }}>{`https://cdn.cboe.com/resources/options/margin_manual_april2000.pdf`}</a>{`
`}<a parentName="p" {...{
        "href": "https://www.tradestation.com/pricing/options-margin-requirements/"
      }}>{`https://www.tradestation.com/pricing/options-margin-requirements/`}</a>{`
`}<a parentName="p" {...{
        "href": "https://www.td.com/ca/products-services/investing/td-direct-investing/investor-education/calculating-margin.jsp?referer=none"
      }}>{`https://www.td.com/ca/products-services/investing/td-direct-investing/investor-education/calculating-margin.jsp?referer=none`}</a>{`
`}<a parentName="p" {...{
        "href": "https://www.investopedia.com/terms/s/specialmemorandumaccount.asp"
      }}>{`https://www.investopedia.com/terms/s/specialmemorandumaccount.asp`}</a></p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;