import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

export default function ActionAreaCard({title, description, video}) {
  return (
    <div style={{ display:'flex', justifyContent:'center', width:"100%" , height: '100%'}}>
    <Card sx={{ maxWidth: 355 }}>
      <CardActionArea>
        <CardMedia
        component="iframe"
         src={video}
         allow="autoPlay"
          height="160"
          alt="green iguana"
          allowfullscreen="allowfullscreen"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
          <Typography variant="body2" color="gray">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
    </div>
  );
}
