
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import ReactPlayer from 'react-player'
import Content from "../../components/Content";
import Container from "../../components/Layouts/Container";
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
import Link from '@mui/material/Link';
import StraddleShort from "../../components/Pdf/StraddleShort"
export const meta = {
  title: " This is my Title2",
  chapter: "Chapter1",
  ispublished: true,
  date: "21 dec 2021",
  author: "Pavan Kumaar Mirla"
};
export const ggbParameters = {
  // material_id: "",
  // width: 1700,
  // height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const opts = {
  height: '390',
  width: '510',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Intro = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/2022/short_straddle/shortstraddle_videos0.mp4' mdxType="ReactPlayer" />
  </div>;
export const Videos10 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/2022/short_straddle/shortstraddle_videos10.mp4' mdxType="ReactPlayer" />
  </div>;
export const DefaultBox = () => <div style={{
  padding: 20,
  backgroundColor: 'yellow'
}} />;
export const Text4 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const componetArray = [<DefaultBox mdxType="DefaultBox" />, <Box1 mdxType="Box1" />];
export const indexArray = {
  button1: 1,
  text14: 1
};
export const items1 = [{
  title: 'What is a Shorst/Credit Straddle?',
  subtitle: 'A short straddle profits if the stock does not move too far from the strike/ Strategy gains from drop in volatility and passage of time (time decay). Losses are unlimited. Profits maxed by premium collected.'
}, {
  title: 'Which option strikes and options types are traded?',
  subtitle: 'The straddle sell consists of selling both a put and a call based on the same terms - the same underlying stock, the same strike price, and the same expiration date.'
}, {
  title: 'What makes it a Credit strategy?',
  subtitle: 'Trader is selling two options, hence net cost (a net credit) trade '
}, {
  title: 'What happens if price remains between breakeven prices and time decays?',
  subtitle: 'max profit potential is realized'
}, {
  title: 'What is the Breakeven?',
  subtitle: 'Breakeven is the point at which profit and loss are equal. Two breakeven points exist. The left breakeven point is strike  minus total premium. The right breakeven point is strike K plus total premium.'
}];

const layoutProps = {
  meta,
ggbParameters,
opts,
Box1,
Intro,
Videos10,
DefaultBox,
Text4,
componetArray,
indexArray,
items1
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">












    <h1>{`Short Straddle`}</h1>
    <p>{`Applicable to scenarios when the volatlity is high, that enable collection of higher option premiums.`}</p>

    <Container mdxType="Container">
  <Content items={items1} title={"Basics"} mdxType="Content" />
    </Container>
    <h1>{`Short Straddle Starter pack`}</h1>
    <StraddleShort mdxType="StraddleShort" />
    <h1>{`Interactive`}</h1>
  <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/short_straddle_scripted.json" ggbParameters={ggbParameters} appId="id1shortStraddle" email="pavanmirla@gmail.com" data={[{
      component: <Intro mdxType="Intro" />,
      button: "videos0"
    }, {
      component: <Videos10 mdxType="Videos10" />,
      button: "videos10"
    }]} mdxType="GeogebraDraw" />
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;