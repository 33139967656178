
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import  { useEffect, useState, useReducer, useContext } from "react";
import TourComponent from "../../components/TourComponent"
import Tour from 'reactour'
import SvgTest from "../../images/SvgDeficits.js";
import MemoSvgComponent from "../../images/TestClickZone.js";
import Canva from "../../images/CanvaEx.js"
import SvgComponent from "../../images/DrawIOSvg.js"
import BeginnerJourney from "../../images/BeginnerJourney"
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from "@material-ui/icons/Close";
import myIcon from '../../images/deficits4.svg'
import VideoSection from "../../components/VideoSection";
import { Portal } from "react-portal";
import { ImageMap } from "@qiuz/react-image-map";
export const StyledBox = styled('div')(({
  theme,
  width
}) => ({
  color: 'darkslategray',
  backgroundColor: '#FFCE00',
  padding: 8,
  borderRadius: 4,
  margin: "auto",
  textAlign: "center",
  width: width,
  fontFamily: 'Monospace'
}));
export const stepStyle = {
  color: '#5ae'
};
export const meta = {
  title: " This is my Title2",
  chapter: "Chapter1",
  ispublished: true,
  date: "21 dec 2021",
  author: "Pavan Kumaar Mirla"
};
export const ggbParameters = {
  // material_id: "",
  // width: 1800,
  // height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const opts = {
  height: '390',
  width: '510',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="EfmTWu2yn5Q" opts={opts} mdxType="YouTube" />
  </div>;
export const MoveHereD = () => <div id="geogebra_element" style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
  </div>;
export const Box2 = () => <div style={{
  padding: 20,
  backgroundColor: 'green'
}} />;
export const DefaultBox = () => <div style={{
  padding: 20,
  backgroundColor: 'yellow'
}} />;
export const Text4 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="HHKwnUa3txo" opts={opts} mdxType="YouTube" />
  </div>;
export const componetArray = [<DefaultBox mdxType="DefaultBox" />, <Box1 mdxType="Box1" />, <Box2 mdxType="Box2" />];
export const indexArray = {
  button1: 1,
  text14: 1
};
export const onMapClick = (area, index) => {
  const tip = `click map${area.href || index + 1}`;
  console.log(tip);
};
export function useStickyState(defaultValue, key) {
  const [value, setValue] = React.useState(() => {
    const stickyValue = window.localStorage.getItem(key);
    return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
  });
  React.useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);
  return [value, setValue];
}
export function Example() {
  const [isOpen, setIsOpen] = React.useState(false);

  const onMapClick = (area, index) => {
    const tip = `click map${area.href || index + 1}`;
    alert(isOpen + "isOpen before set");
    window.localStorage.setItem("isOpenKey", true);
    setIsOpen(!isOpen);
    alert(isOpen + "isOpen after set");
  }; //  React.useEffect(() => {
  //   alert(`Hi you clicked ${isOpen} times`)
  //   setIsOpen(true)
  // },[window.localStorage.getItem("isOpenKey")])


  const Box1 = () => <div style={{
    padding: 20,
    backgroundColor: 'tomato'
  }}>
    <YouTube videoId="EfmTWu2yn5Q" opts={opts} mdxType="YouTube" />
  </div>;

  const component_list = [{
    component: <Box1 mdxType="Box1" />,
    button: "button1"
  }, {
    component: <Box1 mdxType="Box1" />,
    button: "text14"
  }, {
    component: <Box1 mdxType="Box1" />,
    button: "text4"
  }];
  const mapArea = [{
    left: "50%",
    top: "6%",
    height: "12%",
    width: "10%",
    style: {
      width: "5%",
      height: "5%",
      padding: "2px",
      textAlign: "center",
      font: "32px Arial, sans-serif"
    },
    render: (area, index) => <div style={{
      zIndex: '200'
    }}>
          <AddCircleIcon style={{
        color: 'red'
      }} mdxType="AddCircleIcon" />
      </div>,
    onMouseOver: () => {
      // alert("map onMouseOver" + isOpen) 
      debugger; // setIsOpen(false)
    }
  }, {
    width: "10%",
    height: "42%",
    left: "0%",
    top: "30%",
    render: (area, index) => <div style={{
      zIndex: '200'
    }}>
          <TouchAppIcon mdxType="TouchAppIcon" />
      </div>,
    onMouseOver: () => {
      console.log("map onMouseOver");
      setIsOpen(!isOpen);
    }
  }];
  const img = "https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/poster_pages.png";
  const ImageMapComponent = React.useMemo(() => <>
        {isOpen && <div> <Portal mdxType="Portal">  
        <div className="modal" style={{
          position: 'fixed',
          top: 20,
          float: ' right',
          left: 10,
          width: 400,
          zIndex: 10000
        }}>
         {component_list[1].component} 
         <button style={{
            position: 'absolute',
            top: 5,
            float: ' right',
            right: 5
          }} onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? "cc" : "Open "}
      </button>
      </div>
       </Portal>
      </div>}
      <ImageMap className="usage-map" src={"https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/poster_pages.png"} map={mapArea} onMapClick={onMapClick} mdxType="ImageMap" />
      </>, [img]);
  return <div>{ImageMapComponent}</div>;
}
export function Example2() {
  const img = "https://storage.googleapis.com/chapters-ifm/testfolder/2022/deficits4.png";
  const ImageMapComponent = React.useMemo(() => <ImageMap className="usage-map" src={img} map={mapArea} onMapClick={onMapClick} mdxType="ImageMap" />, [img]);
  return <div>{ImageMapComponent}</div>;
}

const layoutProps = {
  StyledBox,
stepStyle,
meta,
ggbParameters,
opts,
Box1,
MoveHereD,
Box2,
DefaultBox,
Text4,
componetArray,
indexArray,
onMapClick,
useStickyState,
Example,
Example2
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
















    <h1>{`DELTA AND GAMMA BASIC MATH`}</h1>
    <br />
    <p>{`Objective: `}<span style={{
        color: "gold"
      }}>{` Graphical representation`}</span>{` of delta and gamma. We'll use Taylor series approximations`}</p>
    <br />
    <VideoSection video={"https://storage.googleapis.com/chapters-ifm/testfolder/2022/delta_gamma_taylor/updated_taylor_video.mp4"} mdxType="VideoSection" />
    <br />
    <p>{`First, we must discuss the Taylor series in order to understand the concept of sensitivity.`}</p>
    <br />
    <p>{`You can approximate any function with polynomials. The coefficients here are based on the derivatives at a single point.`}</p>
    <br />
    <p>{`An approximation of first order is a straight line. Approximations of second order are parabolic`}</p>
    <br />
    <p>{`As we add more terms, the approximation generally gets better.`}</p>
    <br />
    <p>{`Click each curve for an audio note. Do you prefer video or audio? `}</p>
    <br />
  <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/delta_gamma_taylor/taylor_delta_gamma_sound_12.json" ggbParameters={ggbParameters} appId="id2" email="pavanmirla@gmail.com" data={[{
      component: <Box1 mdxType="Box1" />,
      button: "button1"
    }, {
      component: <Box2 mdxType="Box2" />,
      button: "text14"
    }, {
      component: <Text4 mdxType="Text4" />,
      button: "text4"
    }]} mdxType="GeogebraDraw" />
    <h1>{`ADDITIONAL INFORMATION. SLOPE AND CURVATURE`}</h1>
    <br />
    <br />
    <p>{`Graph shows change in call price when we change stock by dS .`}</p>
    <br />
    <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/delta_gamma_taylor/theory_delta_gamma_average_1.json" ggbParameters={ggbParameters} appId="DefinfitionVector1" email="pavanmirla@gmail.com" data={[]} mdxType="GeogebraDraw" />





    <br />
    <p>{`Please send feedback to `}<a parentName="p" {...{
        "href": "mailto:office@21ifm.com"
      }}>{`office@21ifm.com`}</a>{` `}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;