/**
  This CodeSandbox has been automatically generated using
  `codesandboxer`. If you're curious how that happened, you can
  check out our docs here: https://github.com/codesandbox/codesandboxer

  If you experience any struggles with this sandbox, please raise an issue
  on github. :)
*/
import React from "react";
import ReactDOM from "react-dom";
import BlogsApp from "./blogsApp";
import AuthProvider from "./provider/AuthProvider";
import {MDXProvider} from '@mdx-js/react'
import CodeBlock from './markdown-styles/CodeBlock'
import Header1 from './markdown-styles/Header1'
import Header3 from './markdown-styles/Header3'
import Paragraph from './markdown-styles/Paragraph'
import Link_ from './markdown-styles/Link_'
import Thing from './markdown-styles/Sections'
import { BlocksProvider } from 'mdx-blocks'
import AppHeader from "./components/AppHeader";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const components = {
    h1:  Header1,
    h3:  Header3,
    p:   Paragraph,
    a:   Link_,
    pre: CodeBlock,
    thing: Thing,
    wrapper: ({ ...props}) => (
      <div style={{ backgroundColor: '#1E1E1E'}}>
        <main {...props} />
      </div>
    )

  //  code: props => <pre style={{color: 'tomato'}} {...props} />
}

ReactDOM.render(
  [
    <HelmetProvider>
     <Helmet>
       <script type="text/javascript" src="https://cdn.geogebra.org/apps/deployggb.js"></script>
       </Helmet>
     <MDXProvider components={components}>
      <AuthProvider>
        <BlogsApp />
      </AuthProvider>
    </MDXProvider>


    </HelmetProvider>
  ],
  document.getElementById("root")
);
