
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import { useEffect, useState } from "react";
import SimpleAccordion from "../../components/SimpleAccordian";
import IfPaidUser from "../../components/IfPaidUser";
import useFetchData from "../../components/UseFetchData";
import ReactPlayer from 'react-player'
import Stack from '@mui/material/Stack';
import Button from "@material-ui/core/Button";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Papa from "papaparse";
import ReactPlayerComp from "../../components/ReactPlayerComp";
import GRCard from "../../components/GRCard";
import Content from "../../components/Content";
import Container from "../../components/Layouts/Container";
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
export const data = [{
  question: "one",
  answer: "answer"
}, {
  question: "one",
  answer: "answer"
}];
export const items1 = [{
  title: 'Black Scholes formula: Logic',
  subtitle: 'The Black Scholes equation results in a curved relationship between the call premium and the price of the underlying.Black Scholes assumes that the portfolio is delta neutral. This is a long call position and certain units of stocks such that the portfolio is unaffected by stock price changes. Probability of exercise of the option is estimated. Conditional on that the delta neutral posiiton is closed. This results in option payoff formula. Details are in the chart below.'
}, {
  title: 'Influence of Time to expiry on option premium',
  subtitle: 'When the time to maturity is longer, the expected return is higher. Therefore, the buyer is willing to pay more for more maturity calls..'
}, {
  title: 'Influence of strike price on option premium',
  subtitle: 'The strike price. If S/K ratio is high, the buyer is willing to pay more for the option.'
}, {
  title: 'Influence of volatility on option premium',
  subtitle: 'If volatility is high, there is a greater chance of a very high stock price and, therefore, a higher payout for the call.'
}];
export const items2 = [{
  title: 'What is d1?',
  subtitle: 'The mean of the log normal distribution is assumed to be the forward price of the stock. the relative distance from stock to strike in log normal terms in d1.d1 is a standardized value. That means value standardized with mean 0 and sigma 1 '
}, {
  title: 'What is d2?',
  subtitle: 'The median of the log distribution of future stock price in the next period is d2.'
}, {
  title: 'What is N(d1)',
  subtitle: 'N(d1) gives probability that a variable with N(0,1), standard normal distribution will have a value less than d1.'
}, {
  title: 'What is N(d2)',
  subtitle: 'If volatility is high, there is a greater chance of a very high stock price and, therefore, a higher payout for the call.'
}, {
  title: 'What is S x N(d1)?',
  subtitle: 'A call option holder is assumed to close his stock that was created earlier to maintain a delta neutral portfolio when the option is exercised. The current stock price will be multiplied by the number of stocks given by the delta of the call N(d1)'
}];
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Text9 = () => <div style={{
  backgroundColor: 'tomato'
}}>
    <ReactPlayer url='https://storage.googleapis.com/chapters-ifm/testfolder/videos/Theta_video_drive.mp4' mdxType="ReactPlayer" />
  </div>;
export const Text14 = () => <div style={{
  backgroundColor: 'tomato'
}}>
    <ReactPlayerComp url='https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4' mdxType="ReactPlayerComp" />
  </div>;
export const ggbParameters = {
  // material_id: "",
  // width: "100%",
  // height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const items3 = [{
  title: 'Pricing Options. Non linear Options payoff',
  subtitle: 'Understanding the intuition behind non-linear payoff of the options (call and put) can assist in identifying the extrinsic value components of option premiums. Understanding non-linear payoffs will help determine the sensitivity of option prices to volatility, time, and the underlying.  '
}, {
  title: 'What should I keep in mind?',
  subtitle: 'Revise the delta neutral assumption.Consider the assumptions such as constant volatility. Solve several numerical problems'
}];

const layoutProps = {
  data,
items1,
items2,
Box1,
Text9,
Text14,
ggbParameters,
items3
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Black Scholes Option pricing intuition`}</h1>


    <Container mdxType="Container">
  <Content items={items1} title={"Basics"} mdxType="Content" />
  {
        /* <ContentTextBoxes/> */
      }
   {
        /* <Content items={items}/> */
      }
    </Container>
    <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/black_scholes_problem_app_10.json" ggbParameters={ggbParameters} appId="appId2" email="pavanmirla@gmail.com" data={[{
      component: <Text9 mdxType="Text9" />,
      button: "text14"
    }, {
      component: <Text9 mdxType="Text9" />,
      button: "text9"
    }]} mdxType="GeogebraDraw" /> 

    <Container mdxType="Container">
  <Content items={items2} title={"Formula components"} mdxType="Content" />
    </Container>
    <br />
    <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/basics/black_scholes_illustration_with_density.json" ggbParameters={ggbParameters} appId="appIdDensity" email="pavanmirla@gmail.com" data={[{
      component: <Text14 mdxType="Text14" />,
      button: "text14"
    }]} mdxType="GeogebraDraw" /> 





    <Container mdxType="Container">
  <Content items={items3} title={"Where and how to use it?"} mdxType="Content" />
    </Container>
    <br />
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;