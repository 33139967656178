


import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';


export default function SimpleAccordion(props) {
  alert(JSON.stringify(props))
  return (
    <div style={{width:"50%"}}>
      <Typography align={"left"} >{"TOP QUESTIONS"}</Typography>
      {
        props.data.map((q,i) => {
         return <>
      
          <Accordion key={i} >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{q.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {q.answer}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </>
        })
      }

     </div>
  );
}
